import { FC, useEffect, useState } from 'react'

import { Box, Skeleton } from '@mui/material'
import axios from 'axios'
import { debounce } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'

import { Video as VideoType } from 'src/modules/KnowledgeBase/types'
import { routes } from 'src/router'
import { IOption } from 'src/ui/Select'

import BaseGrid from '../BaseGrid'
import BaseHeader from '../BaseHeader'

const Video: FC<{ videoData?: VideoType }> = ({ videoData }) => {
  const location = useLocation()
  const [video, setVideo] = useState<VideoType | undefined>(
    location.state?.video ?? videoData,
  )
  const [filters, setFilters] = useState<IOption[]>([])
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { id } = useParams()

  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PROD

  const handleSearchDebounced = debounce((value: string): void => {
    setSearchQuery(value)
  }, 500)

  const handleFilters = (filter: any): void => {
    setSelectedFilters(filter)
  }

  const setExistingFilters = (existingFilters: string[]): void => {
    const mappedFilters = existingFilters.map((filter) => {
      return {
        label: filter.split('')[0].toUpperCase() + filter.slice(1),
        value: filter,
      }
    })
    setFilters(mappedFilters)
  }

  useEffect(() => {
    const videoId = videoData?.id ?? location.state?.video?.id ?? id

    const getVideo = async (): Promise<void> => {
      const response = await axios.get(
        `video-tutorials/${videoId}`,
      )

      setVideo(response.data.data)
    }

    if (!location.state?.video && !videoData) {
      getVideo().then().catch(console.error)
    } else {
      setVideo(location.state?.video ?? videoData)

      location.state.video = undefined
    }
  }, [id, videoData])

  if (!video && !isLoading) return <h3>Video Not Found!</h3>

  return (
    <>
      <BaseHeader
        title={video?.title ?? 'Video'}
        subtitle="In this section you can find out how to leverage the use of your Pride Platform."
        backButtonLink={`${routes.knowledgeBaseVideos}`}
        onSearchChange={(searchTerm) => {
          handleSearchDebounced(searchTerm)
        }}
        breadcrumbText={video?.title ?? 'Video'}
        onFilterChange={handleFilters}
        filters={filters}
      />

      <Box
        sx={{
          width: '100%',
          aspectRatio: '6 / 3',
          mb: '4rem',
          position: 'relative',
        }}
      >
        {isLoading && (
          <Skeleton
            variant="rectangular"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        )}
        <iframe
          src={`${video?.videoUrl}?autoplay=1`}
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block', border: 'none' }}
          title={video?.title ?? 'Video'}
          width="100%"
          height="100%"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Box>
      <Box
        component="h3"
        sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          mb: '2rem',
          color: '#000',
        }}
      >
        Similar Videos
      </Box>
      <BaseGrid
        filters={selectedFilters}
        getFilters={setExistingFilters}
        key={video?.id}
        searchQuery={searchQuery}
        endpoint={`video-tutorials`}
        extraQueries={video?.id ? `currentItemId=${video?.id}` : ''}
        type="video"
        limit={3}
        skeletonCount={3}
      />
    </>
  )
}

export default Video
