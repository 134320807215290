import { ChangeEvent, FC } from 'react'

import { useTheme } from '@mui/material'
import { Box, SxProps, Theme } from '@mui/system'
import SVG from 'react-inlinesvg'

import { IconThickSearch } from 'src/assets/icons'
import customTheme from 'src/themes/themeLight'
import { CustomButton } from 'src/ui/Button'
import { Input } from 'src/ui/Input'
import { IOption, Select } from 'src/ui/Select'

interface SearchProps {
  buttonTitle?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  styles?: SxProps<Theme> | undefined
  searchStyles?: SxProps<Theme> | undefined
  buttonIcon?: string
  width?: number
  hasFilters?: boolean
  onFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void
  options?: IOption[]
  isMultiFilter?: boolean
  selectWidth?: number
  buttonStyles?: SxProps<Theme> | undefined
  selectLabel?: {
    name: string
    id: string
  }
  selectStyles?: SxProps<Theme> | undefined
  value?: string
}

export const Search: FC<SearchProps> = ({
  buttonTitle,
  onChange,
  onFilterChange,
  onClick,
  buttonIcon,
  width,
  styles,
  hasFilters,
  options,
  isMultiFilter = false,
  searchStyles,
  selectWidth = 200,
  buttonStyles,
  selectLabel,
  selectStyles,
  value,
}) => {
  const theme = useTheme<typeof customTheme>()
  return (
    <Box sx={{ display: 'flex', height: '100%', ...styles }}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: width ? `${width}px` : 'auto',
          paddingRight: '1rem',
        }}
      >
        <SVG
          src={IconThickSearch}
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '20px',
            zIndex: 1,
          }}
        />
        <Input
          onChange={onChange}
          placeholder="Search"
          value={value}
          styles={{
            height: '100%',
            pl: '48px',
            backgroundColor: theme.palette.static.main,
            ...searchStyles,
          }}
        />
      </Box>

      {hasFilters && onFilterChange && (
        <Select
          width={selectWidth}
          onChange={onFilterChange}
          options={options}
          isSmall={true}
          isMultiSelect={isMultiFilter}
          selectLabel={selectLabel}
          values={options?.map((option) => option.value)}
          styles={{ ...selectStyles }}
        />
      )}

      {buttonTitle && (
        <CustomButton
          appearance="primary-soft-default"
          type="button"
          onClick={onClick}
          styles={{ ml: '1rem', ...buttonStyles }}
          icon={buttonIcon}
        >
          {buttonTitle}
        </CustomButton>
      )}
    </Box>
  )
}
