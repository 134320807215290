import { useCallback, useEffect, useState } from 'react'

import { MapStyle as MapStyleOption, availableMapStyles } from '../MapSelector'
import axios from 'axios'

export function useActiveMapStyles(): [
  MapStyleOption,
  (styles: MapStyleOption) => void,
] {
  const [activeMapStyles, _setActiveMapStyles] = useState(availableMapStyles[0])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')

    const activeMapStyles = user.mapStyle

    const stlye = availableMapStyles.find(
      (style) => style.name === activeMapStyles,
    )

    _setActiveMapStyles(stlye ?? availableMapStyles[0])
  }, [])

  const setActiveMapStyles = useCallback((styles: typeof activeMapStyles) => {
    _setActiveMapStyles(styles)
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')

    void axios.patch('/update-user', { mapStyle: styles.name }).then((res) => {
      _setActiveMapStyles(styles)
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, mapStyle: styles.name }),
      )
    })
  }, [])

  return [activeMapStyles, setActiveMapStyles]
}
