import { RouteObject } from 'react-router'
import { createBrowserRouter, redirect } from 'react-router-dom'

import { AccountSettingsPage } from 'src/pages/AccountSettings'
import { ApiKeysPage } from 'src/pages/ApiKeys'
import { FleetViewPage } from 'src/pages/FleetView'
import { ForgotPasswordPage } from 'src/pages/ForgotPassword'
import { KnowledgeBasePage } from 'src/pages/KnowledgeBase'
import Articles from 'src/pages/KnowledgeBase/children/Articles'
import Article from 'src/pages/KnowledgeBase/children/children/Article'
import Video from 'src/pages/KnowledgeBase/children/children/Video'
import Videos from 'src/pages/KnowledgeBase/children/Videos'
import { LineViewPage } from 'src/pages/LineView'
import { LoginPage, loginPageAction, loginPageLoader } from 'src/pages/Login'
import { MachineViewPage } from 'src/pages/MachineView'
import { ReportsPage } from 'src/pages/Reports'
import { ResetPasswordPage } from 'src/pages/ResetPassword'
import { Root, rootLoader } from 'src/pages/Root'
import { UserManagementPage } from 'src/pages/UserManagement'
import { PrivacyPolicePage } from 'src/pages/Privacy'
import MyNotificationPage from 'src/pages/Notifications/MyNotificationPage'
import ActiveLearningPage from 'src/pages/ActiveLearning/ActiveLearningPage'
import ActiveLearningSamplePage from 'src/pages/ActiveLearningSample/ActiveLearningSamplePage'
import { FleetData } from 'src/modules/FleetData'
import { FleetMap } from 'src/modules/FleetMap'
import { FleetLog } from 'src/modules/FleetLog'
import { BillingPage } from 'src/pages/Billing'

export enum routes {
  fleetView = '/',
  fleetMap = '/map',
  fleetLog = '/log',
  myNotification = '/my-notifications',
  login = '/login',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  userManagement = '/user-management',
  reports = '/reports',
  management = '/management',
  activeLearning = '/active-learning',
  activeLearningSample = '/active-learning/:id',
  accountSettings = '/account-settings',
  privacyPolicy = '/privacy-policy',
  knowledgeBase = '/faq-&-tutorials',
  knowledgeBaseVideos = '/faq-&-tutorials/video-tutorials',
  knowledgeBaseVideo = '/faq-&-tutorials/video-tutorials/:id',
  knowledgeBaseArticles = '/faq-&-tutorials/articles',
  knowledgeBaseArticle = '/faq-&-tutorials/articles/:id',
  machineView = '/factories/:plantId/lines/:lineId/machine/:bodymakerId',
  lineView = '/factories/:plantId/lines/:lineId',
  apiKeys = '/api-keys',
  billing = '/billing',
}

const routesArray: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        path: routes.fleetView,
        element: <FleetViewPage />,
        children: [
          {
            path: '',
            element: <FleetData />,
          },
          {
            path: routes.fleetMap,
            element: <FleetMap />,
            children: [{ path: ':plantId' }],
          },
          {
            path: routes.fleetLog,
            element: <FleetLog />,
          },
        ],
      },
      {
        path: routes.userManagement,
        element: <UserManagementPage />,
      },
      {
        path: routes.accountSettings,
        element: <AccountSettingsPage />,
      },
      {
        path: routes.knowledgeBase,
        element: <KnowledgeBasePage />,
      },
      {
        path: routes.knowledgeBaseVideos,
        element: <Videos />,
      },
      {
        path: routes.knowledgeBaseVideo,
        element: <Video />,
      },
      {
        path: routes.knowledgeBaseArticle,
        element: <Article />,
      },
      {
        path: routes.knowledgeBaseArticles,
        element: <Articles />,
      },
      {
        path: routes.machineView,
        loader: () => {
          return import('src/pages/MachineView/MachineViewPage')
        },
        element: <MachineViewPage />,
      },
      {
        path: routes.activeLearning,
        element: <ActiveLearningPage />,
      },
      {
        path: routes.activeLearningSample,
        element: <ActiveLearningSamplePage />,
      },
      {
        path: routes.lineView,
        loader: () => {
          return import('src/pages/LineView/LineViewPage')
        },
        element: <LineViewPage />,
      },
      { path: routes.reports, element: <ReportsPage /> },
      {
        path: routes.privacyPolicy,
        lazy: () =>
          import('src/pages/Privacy/PrivacyPolice').then((module) => ({
            Component: module.default,
          })),
      },
      {
        path: routes.apiKeys,
        element: <ApiKeysPage />,
      },
      {
        path: routes.billing,
        element: <BillingPage />,
      },
      {
        path: routes.myNotification,
        element: <MyNotificationPage />,
      },
    ],
  },
  {
    path: routes.forgotPassword,
    loader: () => {
      return import('src/pages/ForgotPassword')
    },
    element: <ForgotPasswordPage />,
  },
  {
    path: routes.resetPassword,
    loader: () => {
      return import('src/pages/ResetPassword')
    },
    element: <ResetPasswordPage />,
  },
  {
    path: '/login',
    loader: loginPageLoader,
    action: loginPageAction,
    element: <LoginPage />,
  },

  {
    path: '*',
    loader: () => {
      return redirect(routes.fleetView)
    },
  },
]

export const router = createBrowserRouter(routesArray)
