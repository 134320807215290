import { FC, useState, memo, useCallback, useEffect } from 'react'

import { Box, SxProps, Theme } from '@mui/system'
import { Link, useLocation } from 'react-router-dom'

import { ImageProfile } from 'src/assets/images'
import { routes } from 'src/router'
import { Burger } from 'src/ui/Burger'
import { Image } from 'src/ui/Image'
import { disconnectSocket } from 'src/helpers/socketManager'

const defaultContainerStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  height: '40px',
  backgroundColor: '#FFF',
  borderRadius: '4px',
  boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'pointer',
  p: '5px',
}

const openContainerStyles: SxProps<Theme> = {
  width: '210px',
  px: '16px',
  pt: '10px',
  borderRadius: '8px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  backgroundColor: '#FFF',
}

const closeContainerStyles: SxProps<Theme> = {
  px: '5px',
  pt: '5px',
  width: '70px',
  borderRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  '&hover': {
    opacity: '0.1',
    backgroundColor: 'action.main',
  },
}

const defaultContainerLinksStyles: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflow: 'hidden',
  right: 0,
  top: '40px',
  zIndex: 10,
  fontSize: '14px',
  lineHeight: '20px',
  backgroundColor: '#FFF',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}

const openContainerLinksStyles: SxProps<Theme> = {
  width: '210px',
  height: 'min-content',
  opacity: 1,
  p: '16px 16px 24px',
}

const closeContainerLinksStyles: SxProps<Theme> = {
  width: 0,
  height: 0,
  opacity: 0,
  p: 0,
}

export const ProfileMenu: FC = memo(() => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (user.role === 'globalAdmin') {
      setIsGlobalAdmin(true)
    }
  }, [])
  const toggle = useCallback(
    (event: any) => {
      event.stopPropagation()
      setOpen(!isOpen)
    },
    [isOpen],
  )

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  // auto close on dom event
  useEffect(() => {
    document.addEventListener('click', close)

    return () => {
      document.removeEventListener('click', close)
    }
  }, [])

  const match = useLocation()

  const isActiveRoute =
    match.pathname.includes(routes.accountSettings) ||
    match.pathname.includes(routes.knowledgeBase)

  return (
    <Box
      onClick={toggle}
      sx={Object.assign(
        defaultContainerStyles,
        isActiveRoute && !isOpen
          ? { backgroundColor: '#1068EB', boxShadow: 'none' }
          : {
              backgroundColor: '#FFF',
              boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.1)',
            },
        isOpen ? openContainerStyles : closeContainerStyles,
      )}
    >
      <Burger isOpen={isOpen} setOpen={setOpen} isActiveRoute={isActiveRoute} />
      <Image
        src={ImageProfile}
        styles={{
          width: '30px',
          height: '30px',
          border: '1px solid #1068EB',
          borderRadius: '100px',
          overflow: 'hidden',
        }}
      />
      <Box
        sx={Object.assign(
          defaultContainerLinksStyles,
          isOpen ? openContainerLinksStyles : closeContainerLinksStyles,
        )}
      >
        <Link to={routes.accountSettings} onClick={() => setOpen(!isOpen)}>
          Account Settings
        </Link>
        {isGlobalAdmin && (
          <Link to={routes.apiKeys} onClick={() => setOpen(!isOpen)}>
            API Keys
          </Link>
        )}

        <Link to={routes.privacyPolicy} onClick={() => setOpen(!isOpen)}>
          Privacy Policy
        </Link>
        <Link to={routes.knowledgeBase} onClick={() => setOpen(!isOpen)}>
          FAQ & Tutorials
        </Link>
        <Link
          style={{
            color: '#DC2020',
            fontWeight: 700,
          }}
          to={routes.login}
          onClick={() => {
            localStorage.removeItem('user')
            disconnectSocket()
          }}
        >
          Sign Out
        </Link>
      </Box>
    </Box>
  )
})
