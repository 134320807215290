import { FC, useEffect, useState } from 'react'

import { Box, Pagination, useTheme } from '@mui/material'
import axios from 'axios'

import customTheme from 'src/themes/themeLight'
import { MediaCardProps } from 'src/ui/Card/MediaCard'
import MediaGrid from 'src/ui/Card/MediaGrid'

import { PaginationProps } from './types/pagination-props'

interface BaseGridProps {
  searchQuery: string
  endpoint: string
  type: 'video' | 'article'
  limit?: number
  skeletonCount?: number
  extraQueries?: string
  filters?: string[]
  getFilters: (filters: string[]) => void
}

const BaseGrid: FC<BaseGridProps> = ({
  searchQuery,
  endpoint,
  type,
  limit,
  skeletonCount = 6,
  extraQueries,
  filters,
  getFilters,
}) => {
  const theme = useTheme<typeof customTheme>()
  const [loading, setLoading] = useState(true)
  const [existingFilters, setExistingFilters] = useState<string[]>([])
  const [pagination, setPagination] = useState<PaginationProps>({
    currentPage: 1,
    total: 0,
    pageSize: 9,
    onPageChange: (event, page) => {
      setPagination({
        ...pagination,
        currentPage: page,
      })
    },
  })
  // const [data, setData] = useState<MediaCardProps[]>([])
  const [filteredData, setFilteredData] = useState<MediaCardProps[]>([])

  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PROD

  useEffect(() => {
    setLoading(true)
    async function fetchData(): Promise<void> {
      try {
        let url = `${endpoint}`
        if (pagination.currentPage) {
          url += `?page=${pagination.currentPage}`
        }
        if (searchQuery) {
          url += `&searchQuery=${searchQuery}`
        }
        if (filters && filters.length > 0) {
          url += `&filters=${filters.join(',')}`
        }
        if (limit) {
          url += `&limit=${limit}`
        }
        if (extraQueries) {
          url += `&${extraQueries}`
        }
        const response = await axios.get(url)
        setPagination({
          ...pagination,
          total: response.data.totalItems,
          pageSize: limit ?? 9,
        })

        // setData(response.data.items)

        if (
          response.data.existingTags &&
          existingFilters.length < 1 &&
          getFilters
        ) {
          setExistingFilters(response.data.existingTags)
          getFilters(response.data.existingTags)
        }
        setFilteredData(response.data.items)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData().then().catch(console.error)
  }, [pagination.currentPage, searchQuery, filters])

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ): void => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: page,
    }))
  }

  return (
    <>
      <MediaGrid
        media={filteredData}
        type={type}
        loading={loading}
        skeletonCount={skeletonCount}
        query={searchQuery}
      />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '2rem',
          mb: '4rem',
        }}
      >
        <Box
          component={'p'}
          sx={{ fontSize: '0.875rem', color: theme.palette.secondary.main }}
        >
          {pagination.total === 0
            ? 0
            : pagination.currentPage * pagination.pageSize -
              (pagination.pageSize - 1)}{' '}
          to{' '}
          {pagination.total < pagination.pageSize
            ? pagination.total
            : pagination.pageSize * pagination.currentPage < pagination.total
            ? pagination.pageSize * pagination.currentPage
            : pagination.total}{' '}
          of {pagination.total} records
        </Box>
        <Pagination
          count={Math.ceil(pagination.total / pagination.pageSize)}
          page={pagination.currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          sx={{
            '& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)': {
              backgroundColor: theme.palette.static.main,
              color: '#4F575E',
              '&.Mui-selected': {
                color: theme.palette.action.main,
                borderColor: theme.palette.action.main,
              },
            },
          }}
        />
      </Box>
    </>
  )
}

export default BaseGrid
