import React, { FC } from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'

const HorizontalStepper: FC<{ steps: string[]; activeStep: number }> = ({
  steps,
  activeStep,
}) => {
  const activeStepStyles = {
    svg: {
      border: '1px solid #29823B',
      borderRadius: '50%',
      color: '#fff !important',
      text: {
        fill: '#29823B',
      },
    },
  }

  const completedStyles = {
    svg: {
      color: '#29823B !important',
      text: {
        fill: '#6A7178',
      },
      path: {
        fill: '#29823B',
      },
    },
  }

  const inactiveStyles = {
    svg: {
      border: '1px solid #ADB5BD',
      borderRadius: '50%',
      color: '#F1F3F5',
      text: {
        fill: '#6A7178',
      },
    },
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          backgroundColor: 'transparent',
          width: '100%',
        }}
      >
        {steps.map((label, index) => (
          <Step
            completed={index < activeStep}
            key={label}
            sx={
              index < activeStep
                ? completedStyles
                : index === activeStep
                ? activeStepStyles
                : inactiveStyles
            }
          >
            <StepLabel
              sx={{
                span: {
                  color:
                    index === activeStep
                      ? '#29823B !important'
                      : index < activeStep
                      ? '#4F575E !important'
                      : '#6A7178 !important',

                  fontWeight:
                    index === activeStep ? '500 !important' : '400 !important',
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default HorizontalStepper
