import { FC, SetStateAction } from 'react'

import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import MenuSharpIcon from '@mui/icons-material/MenuSharp'
import { IconButton } from '@mui/material'

interface BurgerProps {
  isOpen: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  isActiveRoute: boolean
}

export const Burger: FC<BurgerProps> = ({ isOpen, setOpen, isActiveRoute }) => {
  return (
    <IconButton
      onClick={() => setOpen((prevState) => !prevState)}
      sx={{
        px: 0,
        width: '24px',
        height: '24px',
      }}
    >
      {isOpen ? (
        <CloseSharpIcon sx={{ color: '#303D60' }} />
      ) : (
        <MenuSharpIcon sx={{ color: isActiveRoute ? '#FFF' : '#303D60' }} />
      )}
    </IconButton>
  )
}
