import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import {
  ImageBlackWhiteMap,
  ImageSatelliteMap,
  ImageStreetMap,
  ImageTerrainMap,
} from 'src/assets/images'
import { Image } from 'src/ui/Image'
import { useActiveMapStyles } from './helper/map.helpers'

export interface MapStyle {
  name: string
  styles?: string
  mapTypeId: string
  image: string
}

export const availableMapStyles: MapStyle[] = [
  {
    name: 'Street Map',
    styles: undefined,
    mapTypeId: 'roadmap',
    image: ImageStreetMap,
  },
  {
    name: 'Black & White',
    styles: 'black-white.style.json',
    mapTypeId: 'roadmap',
    image: ImageBlackWhiteMap,
  },
  {
    name: 'Terrain',
    styles: undefined,
    mapTypeId: 'terrain',
    image: ImageTerrainMap,
  },
  {
    name: 'Satellite',
    styles: undefined,
    mapTypeId: 'satellite',
    image: ImageSatelliteMap,
  },
]
const MapSelector: FC = () => {
  const [activeMapStyle, setActiveMapStyle] = useActiveMapStyles()

  return (
    <Box
      sx={{
        p: '16px 24px 30px',
        mt: '36px',
        bgcolor: '#F1F3F5',
        borderRadius: '8px',
      }}
    >
      <Typography
        variant="h6"
        sx={{ lineHeight: '22px', color: 'primary.main' }}
      >
        Map preview
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          mt: '24px',
        }}
      >
        {availableMapStyles.map((map) => (
          <Box
            key={map.name}
            component={'button'}
            onClick={() => {
              setActiveMapStyle(map)
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
              borderRadius: '4px',
              height: '86px',
              background: `linear-gradient(97.22deg, 
              #FFFFFF 9.37%, #DEE2E6 95.83%)`,
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              ...(activeMapStyle.name === map.name && {
                outline: '3px solid #1068eb',
              }),
            }}
          >
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                lineHeight: '22px',
                color: '#FFF',
                pt: '16px',
                pl: '16px',
              }}
            >
              {map.name}
            </Typography>
            <img
              alt={map.name}
              src={map.image}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default MapSelector
