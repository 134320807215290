import React, { FC, useEffect, useState } from 'react'

import {
  Box,
  Select as MUISelect,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
  InputLabel,
  FormControl,
} from '@mui/material'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import { toast } from 'react-toastify'

import { IconBuildingFactory } from 'src/assets/icons'
import { FormField } from 'src/components/FormField'
import customTheme from 'src/themes/themeLight'

import BaseModal from './BaseModal'
import { CustomButton } from '../Button'
import { Input } from '../Input'
import { Select } from '../Select'
interface AddUserProps {
  show: boolean
  onClose: () => void
  onConfirm: (formData: any) => void
}

interface AddUserForm {
  firstName: string
  lastName: string
  username: string
  role: string
  jobTitle: string
  password: string
  phone?: string
  email: string
  organization?: string
  assignedPlants?: number[]
}

const AddUserModal: FC<AddUserProps> = ({ show, onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<AddUserForm>({
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      role: '',
      phone: '',
      email: '',
    },
  })

  const [currentUser, setCurrentUser] = useState<any>({})

  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    setCurrentUser(user)
  }, [])

  const [selectedRole, setSelectedRole] = useState<any>({})
  const [selectedPlants, setSelectedPlants] = useState<any>([])
  const [roles, setRoles] = useState<any[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null)
  const [organizations, setOrganizations] = useState<any[]>([])
  //   useEffect(() => {
  //     setOpen(show)
  //   }, [show])

  const handleClose = (): void => {
    // setOpen(false)
    onClose()
    setSelectedPlants([])
    setSelectedRole({})
    form.reset()
  }

  const handleChange = (event: any) => {
    const inputArray = event.target.value
    const property = 'id'
    const objectCountMap = new Map()

    // Count the occurrences of each property value
    for (const obj of inputArray) {
      const value = String(obj[property])
      objectCountMap.set(
        value,
        ((objectCountMap.get(value) || 0) as number) + 1,
      )
    }

    // Filter out objects with a count greater than one
    const uniquePlants = inputArray.filter((obj: any) => {
      const value = String(obj[property])
      return objectCountMap.get(value) === 1
    })

    setSelectedPlants(uniquePlants)
  }

  const handleConfirm = async (e: any): Promise<void> => {
    e.preventDefault()
    const {
      firstName,
      lastName,
      username,
      password,
      role,
      phone,
      email,
      assignedPlants,
    } = form.getValues()
    const errors = []
    if (!firstName || !lastName || !password || !email || !role) {
      toast.error('Please fill out all fields')
      return
    }
    // if (!firstName) {
    //   errors.push('Username is required')
    // }

    // if (!lastName) {
    //   errors.push('Last name is required')
    // }

    if (!email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i)) {
      errors.push('Please enter a valid email')
    }
    if (
      phone &&
      !phone.match(/^(?:\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^[0-9]{10}$/)
    ) {
      errors.push('Please enter a valid phone number')
    }

    if (password.length < 6) {
      errors.push('Password must be at least 6 characters')
    }

    if (errors.length > 0) {
      errors.forEach((error) => {
        toast.error(error)
      })
      return
    }

    try {
      console.log(form.getValues())
      console.log(
        selectedPlants.map((plant: any) => plant.id),
        '12341234',
      )

      setIsLoading(true)
      const response = await axios.post('/admin/create-user', {
        firstName,
        lastName,
        username,
        role,
        phone,
        password,
        email,
        organizationId: selectedOrganization?.value,
        assignedPlants: selectedPlants.map((plant: any) => plant.id),
      })

      if (response.status === 201) {
        toast.success('User added successfully')
        onConfirm({
          id: response.data.user.id,
          username: response.data.user.username,
          name: response.data.user.username,
          role: roles.find((role) => role.value === response.data.user.role)
            .label,
          roleValue: roles.find(
            (role) => role.value === response.data.user.role,
          ).value,
          phone: response.data.user.phone,
          email: response.data.user.email,
          plants: response.data.user.plants,
          status: response.data.user.status,
          canBeUpdated: true,
        })
        form.reset()
        setSelectedPlants([])
        setSelectedRole({})
        onClose()
      } else {
        throw new Error(response.data.message ?? 'Error adding user')
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
        setIsLoading(false)
        return
      }
      toast.error(err ?? 'Error adding user')
    }

    setIsLoading(false)
  }
  const [plants, setPlants] = useState<any[]>([])
  useEffect(() => {
    const fetchMachineNames = async (): Promise<void> => {
      setSelectedPlants([])
      const response = await axios.get('/data/factory-names', {
        params: {
          organizationId: selectedOrganization?.value,
        },
      })
      console.log(response.data.factories)
      if (response.data.factories.length > 0) {
        setPlants(
          response.data.factories.map((plant: any) => {
            return {
              name: plant.name,
              id: plant.id,
            }
          }),
        )
      } else {
        setPlants([])
      }
    }
    if (currentUser.role === 'globalAdmin' && selectedOrganization !== null) {
      fetchMachineNames().then().catch(console.log)
    } else if (currentUser.role === 'globalAdmin') {
      const fetchOrgNames = async (): Promise<void> => {
        const response = await axios.get('/data/organization-names')
        console.log(response.data.organizations)
        if (response.data.organizations.length > 0) {
          setOrganizations(
            response.data.organizations.map((org: any) => {
              return {
                label: org.name,
                value: org.id,
              }
            }),
          )
        } else {
          setOrganizations([])
        }
      }
      fetchOrgNames().then().catch(console.log)
    } else {
      fetchMachineNames().then().catch(console.log)
    }
  }, [currentUser.role, selectedOrganization])

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      const response = await axios.get('/admin/roles')
      console.log(response.data)
      if (response.data.length > 0) {
        setRoles(
          response.data.map((role: any) => {
            return {
              value: role.value,
              label: role.label,
            }
          }),
        )
      } else {
        setRoles([])
      }
      // setAssignedMachineValue(response.data[0])
    }

    fetchRoles().then().catch(console.log)
  }, [])

  return (
    <BaseModal
      maxWidth="lg"
      title="Create a new user account"
      show={show}
      onClose={handleClose}
    >
      <Box
        component={'form'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          //   flexDirection: 'column',
          gap: '24px',
          padding: '16px 0',
        }}
      >
        <FormField<AddUserForm>
          name="firstName"
          placeholder="First name*"
          form={form}
          component={Input}
          onChange={(e: any) => {
            form.setValue('firstName', e.target.value)
          }}
          required
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />
        <FormField<AddUserForm>
          name="lastName"
          placeholder="Last name*"
          form={form}
          component={Input}
          onChange={(e: any) => {
            form.setValue('lastName', e.target.value)
          }}
          required
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />

        <FormField<AddUserForm>
          name="username"
          placeholder="Username"
          form={form}
          component={Input}
          onChange={(e: any) => {
            form.setValue('username', e.target.value)
          }}
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />

        <FormField<AddUserForm>
          name="email"
          placeholder="E-mail*"
          form={form}
          component={Input}
          required
          onChange={(e: any) => {
            form.setValue('email', e.target.value)
          }}
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />
        <FormField<AddUserForm>
          name="password"
          placeholder="Password*"
          type="password"
          form={form}
          component={Input}
          required
          onChange={(e: any) => {
            form.setValue('password', e.target.value)
          }}
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />
        <Select
          styles={{
            color: 'secondary.main',
            height: '40px',
            maxWidth: '246px',
            minWidth: '150px',
            backgroundColor: '#FFF',
          }}
          placeholder="Select Role*"
          {...form.register('role')}
          options={roles}
          value={
            roles.find((role) => role.value === form.getValues().role)?.value
          }
          onChange={(e: any) => {
            form.setValue('role', e.target.value)

            setSelectedRole({
              value: e.target.value,
              label: roles.find((r) => r.value === e.target.value)?.label,
            })

            console.log(e.target.value, 'e.target.value')
          }}
        />
        {currentUser.role === 'globalAdmin' && (
          <Select
            styles={{
              color: 'secondary.main',
              height: '40px',
              maxWidth: '246px',
              minWidth: '150px',
              backgroundColor: '#FFF',
            }}
            placeholder="Select Organization*"
            {...form.register('organization')}
            options={organizations}
            value={
              organizations.find(
                (org) => org.value === form.getValues().organization,
              )?.value
            }
            onChange={(e: any) => {
              form.setValue('organization', e.target.value)

              setSelectedOrganization({
                value: e.target.value,
                label: organizations.find(
                  (organization) => organization.value === e.target.value,
                )?.label,
              })

              console.log(e.target.value, 'e.target.value')
            }}
          />
        )}
        <FormField<AddUserForm>
          name="phone"
          placeholder="Phone number"
          form={form}
          component={Input}
          onChange={(e: any) => {
            form.setValue('phone', e.target.value)
          }}
          styles={{
            color: 'secondary.main',
            px: '16px',
            py: '9.5px',
            height: '40px',
            maxWidth: '246px',
            backgroundColor: '#FFF',
            '&placeholder-shown': {
              pt: '9.5px',
            },
          }}
        />

        {(currentUser.role !== 'globalAdmin' ||
          selectedOrganization !== null) && (
          <FormControl
            sx={{
              // width: '100%',
              maxWidth: '193px',
            }}
            size={'small'}
          >
            <InputLabel
              // shrink={Boolean(form.getValues().assignedPlantName)}
              sx={{
                '&.MuiInputLabel-shrink': {
                  color: customTheme.palette.action.main,
                },
                '& + .Mui-focused fieldset': {
                  borderColor: customTheme.palette.action.main,
                },
              }}
              id="assignedPlants"
            >
              Select Plants
            </InputLabel>
            <MUISelect
              disabled={
                selectedRole.value !== 'plantViewer' &&
                selectedRole.value !== 'plantSupervisor'
              }
              {...form.register('assignedPlants')}
              labelId="assignedPlants"
              id="plantSelect"
              value={selectedPlants}
              onChange={handleChange}
              label={'Select Plants'}
              placeholder="Select Plants"
              //  ref={ref}
              defaultValue={'Select Plants'}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip key={value.name} label={value.name} size="small" />
                  ))}
                </Box>
              )}
              multiple
            >
              {plants &&
                plants.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    //  style={getStyles(option.value, selectedValues, theme)}
                  >
                    <Checkbox
                      sx={{
                        'input:checked ~ svg': {
                          color: customTheme.palette.action.main,
                        },
                      }}
                      //  checked={selectedValues.includes(option.value)}
                      checked={selectedPlants
                        .map((plant: any) => plant.id)
                        .includes(option.id)}
                    />
                    <SVG
                      src={IconBuildingFactory}
                      style={{
                        fill: '#6A7178',
                        marginRight: '10px',
                        width: '20px',
                      }}
                    />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
            </MUISelect>
          </FormControl>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            mb: '16px',
            width: '100%',
          }}
        >
          <CustomButton
            styles={{ mr: 2 }}
            onClick={handleClose}
            appearance="cancel-soft-default"
            isLoading={isLoading}
          >
            Cancel
          </CustomButton>
          <CustomButton
            // onClick={handleConfirm}
            type="submit"
            appearance="primary-soft-default"
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            Add User
          </CustomButton>
        </Box>
      </Box>
    </BaseModal>
  )
}

export default AddUserModal
