import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { ITableColumns, Table } from 'src/components/Table'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'

const columns: ITableColumns[] = [
  {
    accessor: 'signal',
    header: 'Signal',
    width: 0,
  },
  {
    accessor: 'average',
    header: 'DAY AVG',
    width: 0,
  },
  {
    accessor: 'min',
    header: 'DAY MIN',
    width: 0,
  },
  {
    accessor: 'max',
    header: 'DAY MAX',
    width: 0,
  },
  {
    accessor: 'weeklyAverage',
    header: 'Past Week AVG',
    width: 0,
  },
  {
    accessor: 'monthlyAverage',
    header: 'Past Month AVG',
    width: 0,
  },
  {
    accessor: 'LastUpdated',
    header: 'Last Time Reported',
    width: 0,
  },
]

export interface HistoricalValuesProps {
  bodyMakerId?: string
}

export const HistoricalValues: React.FC<HistoricalValuesProps> = ({
  bodyMakerId,
}) => {
  const [tableData, setTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false)

  useEffect(() => {
    let mounted = true
    const fetchTableData = async (): Promise<void> => {
      setIsTableLoading(true)
      const response = await axios.get(`data/historical-data/${bodyMakerId}`)
      console.log('response', response.data)
      if (mounted) {
        if (!response.data || response.data.length === 0) {
          setIsTableLoading(false)
          return
        }
        setTableData([
          {
            signal: 'Over Travel Value Peak',
            min: response.data?.data.overTravelData?.dayMin,
            max: response.data?.data.overTravelData?.dayMax,
            average: response.data?.data.overTravelData?.dayAvg,
            weeklyAverage: response.data?.data.overTravelData?.weekAvg,
            monthlyAverage: response.data?.data.overTravelData?.monthAvg,
            LastUpdated: (
              <Box
                sx={{
                  p: '4px',
                  display: 'inline-block',
                  borderRadius: '4px',
                  backgroundColor: 'secondary.main',
                  color: '#F8F9FA',
                  whiteSpace: 'nowrap',
                  lineHeight: '18px',
                  fontSize: '12px',
                }}
              >
                {convertTimestampToLocal(response.data.lastUpdated)}
              </Box>
            ),
          },
          {
            signal: 'Dome Settings Value Peak',
            min: response.data.data.domeData.dayMin,
            max: response.data.data.domeData.dayMax,
            average: response.data.data.domeData.dayAvg,
            weeklyAverage: response.data.data.domeData.weekAvg,
            monthlyAverage: response.data.data.domeData.monthAvg,
            LastUpdated: (
              <Box
                sx={{
                  p: '4px',
                  display: 'inline-block',
                  borderRadius: '4px',
                  backgroundColor: 'secondary.main',
                  color: '#F8F9FA',
                  whiteSpace: 'nowrap',
                  lineHeight: '18px',
                  fontSize: '12px',
                }}
              >
                {convertTimestampToLocal(response.data.lastUpdated)}
              </Box>
            ),
          },
          {
            signal: 'Clamp Ring Value Peak',
            min: response.data?.data?.clampRingData?.dayMin,
            max: response.data?.data?.clampRingData?.dayMax,
            average: response.data?.data?.clampRingData?.dayAvg,
            weeklyAverage: response.data?.data?.clampRingData?.weekAvg,
            monthlyAverage: response.data?.data?.clampRingData?.monthAvg,
            LastUpdated: (
              <Box
                sx={{
                  p: '4px',
                  display: 'inline-block',
                  borderRadius: '4px',
                  backgroundColor: 'secondary.main',
                  color: '#F8F9FA',
                  whiteSpace: 'nowrap',
                  lineHeight: '18px',
                  fontSize: '12px',
                }}
              >
                {convertTimestampToLocal(response.data.lastUpdated)}
              </Box>
            ),
          },
          {
            signal: 'Alignment Value Peak',
            min: response.data.data?.alignmentData?.dayMin,
            max: response.data.data?.alignmentData?.dayMax,
            average: response.data.data?.alignmentData?.dayAvg,
            weeklyAverage: response.data?.data.alignmentData?.weekAvg,
            monthlyAverage: response.data?.data.alignmentData?.monthAvg,
            LastUpdated: (
              <Box
                sx={{
                  p: '4px',
                  display: 'inline-block',
                  borderRadius: '4px',
                  backgroundColor: 'secondary.main',
                  color: '#F8F9FA',
                  whiteSpace: 'nowrap',
                  lineHeight: '18px',
                  fontSize: '12px',
                }}
              >
                {convertTimestampToLocal(response.data.lastUpdated)}
              </Box>
            ),
          },
        ])

        console.log('tableData', response.data)

        setIsTableLoading(false)
      }
    }

    if (mounted) {
      fetchTableData()
        .then(() => {
          setIsTableLoading(false)
        })
        .catch((err) => {
          setIsTableLoading(false)
          console.log(err)
        })
    }

    return () => {
      mounted = false
    }
  }, [bodyMakerId])

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        mt: '48px',
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          color: '#101213',
          fontWeight: 700,
          p: '24px',
          pb: 0,
          mb: '-12px',
        }}
      >
        Historical Values
      </Typography>
      <Table
        data={tableData}
        loading={isTableLoading}
        columns={columns}
        containerStyles={{
          mt: '48px',
        }}
        tdStyles={{
          p: '16px',
        }}
      />
    </Box>
  )
}
