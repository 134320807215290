import { FC, useState } from 'react'

import { Box } from '@mui/material'

import { PageHeading } from 'src/components/PageHeading'
import { TabsRow } from 'src/components/TabsRow'
import { AccountNotifications } from 'src/modules/AccountNotifications'
import { AccountProfile } from 'src/modules/AccountProfile'
import { TabContainer } from 'src/ui/TabContainer'

const tabsContent = {
  Profile: <AccountProfile />,
  Notifications: <AccountNotifications />,
}

type TTabsNames = keyof typeof tabsContent

export const AccountSettingsPage: FC = () => {
  const tabsArr = Object.keys(tabsContent) as TTabsNames[]

  const [currentTab, setCurrentTab] = useState<TTabsNames>(tabsArr[0])
  const handleChangeTab = (tabName: TTabsNames): void => {
    setCurrentTab(tabName)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        mt: '48px',
      }}
    >
      <PageHeading title="Account Settings" />
      <TabsRow<TTabsNames>
        tabsArr={tabsArr}
        active={currentTab}
        onClick={handleChangeTab}
        tabsStyles={{ width: '180px' }}
        styles={{ my: '36px' }}
      />
      <TabContainer>{tabsContent[currentTab]}</TabContainer>
    </Box>
  )
}
