import { FC, useEffect } from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ForgotPasswordForm from 'src/modules/ForgotPassword'

export const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/')
    }
  }, [navigate])
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        background: '#DEE2E6',
      }}
    >
      <ForgotPasswordForm />
      <ToastContainer />
    </Box>
  )
}
