import { FC } from 'react'

import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { FormField } from 'src/components/FormField'
import { Input } from 'src/ui/Input'
import { CustomButton } from 'src/ui/Button'
import { toast } from 'react-toastify'

interface MessagesSettingsProps {
  data?: any
  onPhoneUpdate: (phoneNumber: string) => void
  phoneNumber?: string
}

interface IChipData {
  name: string
  type: string
  icon?: JSX.Element
}

const chipData: IChipData[] = [
  {
    name: 'All',
    type: 'primary',
  },
  {
    name: 'Warnings',
    type: 'error',
  },
  {
    name: 'Errors',
    type: 'error',
  },
  {
    name: 'Timeout',
    type: 'error',
  },
  {
    name: 'Operational Again',
    type: 'error',
  },
  {
    name: 'Return from Timeout',
    type: 'primary',
  },
]

export const MessagesSettings: FC<MessagesSettingsProps> = ({
  onPhoneUpdate,
  phoneNumber,
}) => {
  const form = useForm({
    defaultValues: {
      phone: phoneNumber,
    },
  })
  return (
    <Box
      sx={{
        mt: '12px',
      }}
    >
      <Box
        sx={{
          // height: '48px',
          display: 'flex',
          mb: 3,
          gap: '24px',
          color: 'secondary.main',
        }}
      >
        {/* <FormField
          form={form}
          component={Input}
          name="country"
          placeholder="Country"
          styles={{
            height: '48px',
            pl: '16px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
          containerStyles={{
            flexGrow: 1,
          }}
        /> */}
        <FormField
          form={form}
          component={Input}
          value={form.getValues('phone')}
          onChange={(e: any) => {
            form.setValue('phone', e.target.value)
          }}
          name="phone"
          placeholder="Phone number"
          styles={{
            height: '48px',
            pl: '16px',
            fontSize: '16px',
            lineHeight: '24px',
            width: '350px',
          }}
        />
        <CustomButton
          appearance="primary-soft-default"
          onClick={() => {
            if (!form.getValues('phone')) {
              toast.error('Please enter a phone number')
            }
            if (
              !form
                .getValues('phone')
                ?.match(
                  /^(?:\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^[0-9]{10}$/,
                )
            ) {
              toast.error('Please enter a valid US phone number')
            } else {
              onPhoneUpdate(form.getValues('phone')!)
            }
          }}
        >
          Update
        </CustomButton>
        {/* <FormField
          form={form}
          component={Input}
          name="timeFrameInfo"
          placeholder="Snooze info time frame"
          styles={{
            height: '48px',
            pl: '16px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
          containerStyles={{
            flexGrow: 3,
          }}
        /> */}
      </Box>
      <Typography>
        The expected phone formats are: <br />
        (123) 123-1234
        <br /> 1231231234 <br />
        123-123-1234
      </Typography>
      {/* <Typography
        variant="body2"
        sx={{
          mt: '24px',
          color: '#4F575E',
          fontWeight: 300,
        }}
      >
        {'Click on the topics that you would like to receive regular updates. '}
        <Typography
          variant="body2"
          component="span"
          sx={{
            fontWeight: 500,
          }}
        >
          Note: By default you will be updated about all of the topics listed
          below.
        </Typography>
      </Typography>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%',
            mt: '24px',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            position: 'relative',
            scrollbarWidth: 'none',
          }}
        >
          <Box
            sx={{
              width: '173px',
              position: 'absolute',
              height: '100%',
              top: 0,
              right: 0,
              backgroundColor: `linear-gradient(270deg, #FFFFFF 31.9%, 
                rgba(255, 255, 255, 0) 100%, #E9ECEF 100%)`,
            }}
          />
          {chipData.map((chip) => (
            <Box
              key={chip.name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '8px 24px',
                borderRadius: '100px',
                fontWeight: 500,
                cursor: 'pointer',
                color: chip.type === 'error' ? '#FFF' : '#272B30',
                bgcolor: chip.type === 'error' ? '#272B30' : '#E9ECEF',
                '&:last-child': {
                  mr: '96px',
                },
              }}
            >
              {chip.name}
              {chip?.icon}
            </Box>
          ))}
        </Box>
      </Box> */}
    </Box>
  )
}
