import { FC, useLayoutEffect, useRef } from 'react'

import * as d3 from 'd3'

interface DashedLineProps {
  title: string
  value: number
  color: string
  xScale: d3.ScaleTime<number, number>
  yScale: d3.ScaleLinear<number, number, never>
}

export const DashedLine: FC<DashedLineProps> = ({
  value,
  color,
  xScale,
  yScale,
  title,
}) => {
  const lineRef = useRef<SVGLineElement>(null)
  const textRef = useRef<SVGTextElement>(null)
  const areaRef = useRef<SVGRectElement>(null)

  useLayoutEffect(() => {
    const line = d3.select(lineRef.current)

    line
      .attr('x1', xScale(xScale.domain()[0]))
      .attr('x2', xScale(xScale.domain().pop()!))
      .attr('y1', yScale(value))
      .attr('y2', yScale(value))

    d3.select(textRef.current)
      .text(`${title} ${value}`)
      .attr('x', xScale(xScale.domain()[1]) - 10)
      .attr('y', yScale(value) + 15)
      .attr('dy', '0.32em')
      .attr('dx', '0.5em')
      .attr('fill', color)
      .attr('font-size', '0.8em')
      .attr('font-weight', 'normal')
      .attr('text-anchor', 'end')

    /// draw gradient area below line
    d3.select(areaRef.current)
      .attr('x', xScale(xScale.domain()[0]))
      .attr('y', yScale(value))
      .attr('width', xScale(xScale.domain()[1]) - xScale(xScale.domain()[0]))
      .attr('height', 20)
      .attr('fill', 'url(#line-gradient)')
  })

  return (
    <>
      <line
        ref={lineRef}
        id="line-test"
        stroke={color}
        strokeWidth={1}
        strokeDasharray="5,5"
      />

      <rect ref={areaRef} />
      <text ref={textRef} />
    </>
  )
}
