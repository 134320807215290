import { useEffect, useRef, useState } from 'react'

export function useWidth(): [number, React.RefObject<any>] {
  const [width, setWidth] = useState(0)
  const elementRef = useRef<any>(null)

  useEffect(() => {
    const element = elementRef.current
    const resizeObserver = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width)
    })

    if (element) {
      resizeObserver.observe(element)
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [elementRef])

  return [width, elementRef]
}
