import { FC, useState } from 'react'

import { AlertColor, Box, Stack } from '@mui/material'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import { FormField } from 'src/components/FormField'
import customTheme from 'src/themes/themeLight'
import { CustomButton } from 'src/ui/Button'
import { Input } from 'src/ui/Input'
import { Select } from 'src/ui/Select'
import Snackbar from 'src/ui/Snackbars/Snackbar'

import { IUser } from '../../../../types'

interface ProfileFormProps {
  user: IUser
}

type IProfileForm = Pick<
  IUser,
  | 'firstName'
  | 'lastName'
  | 'dateFormat'
  | 'homePage'
  | 'language'
  | 'numberFormat'
  | 'timeFormat'
  | 'timeZone'
>

type TColSelects = Array<{
  id: keyof IProfileForm
  value: string[]
  label: string
}>

const selectMenus: TColSelects = [
  {
    id: 'homePage',
    value: ['Home View', 'Fleet View'],
    label: 'Choose landing page',
  },
  {
    id: 'timeZone',
    value: ['UTC', 'GMT', 'Etc/GMT+3'],
    label: 'Time zone',
  },
  {
    id: 'timeFormat',
    value: ['hh:mm:ss', 'hh:mm', 'hh:mm:ss a'],
    label: 'Time format',
  },
  {
    id: 'language',
    value: ['English', 'Russian', 'Ukrainian'],
    label: 'Language',
  },
  {
    id: 'dateFormat',
    value: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD'],
    label: 'Date format',
  },
  {
    id: 'numberFormat',
    value: ['123,456.78', '123 456,78', '123.456,78'],
    label: 'Number format',
  },
]

// const secondColSelects: TColSelects = [

// ]

export const ProfileForm: FC<ProfileFormProps> = ({ user }) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [snackbar, setSnackbar] = useState<
    { status: AlertColor; message: string } | undefined
  >(undefined)
  const [formData, setFormData] = useState<IProfileForm>({
    firstName: user.firstName,
    lastName: user.lastName,
    dateFormat: user.dateFormat,
    homePage: user.homePage,
    language: user.language,
    numberFormat: user.numberFormat,
    timeFormat: user.timeFormat,
    timeZone: user.timeZone,
  })
  const [errors, setErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm<IProfileForm>({
    defaultValues: formData,
  })

  const handleCancel = (): void => {
    form.reset()
    setFormData(form.getValues())
    setIsDisabled(true)
  }

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true)
    setIsDisabled(true)
    try {
      const req = await axios.patch('update-user', form.getValues())

      if (req.status === 200) {
        setIsLoading(false)
        const updatedUser = { ...user, ...formData }
        localStorage.setItem('user', JSON.stringify(updatedUser))
        setSnackbar({ status: 'success', message: 'User updated successfully' })
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error: any) {
      setIsLoading(false)
      if (error.response?.status === 400) {
        const errorResponse = error.response.data.message
        const errorsObject: any = {}
        if (typeof errorResponse === 'string' && errorResponse.length > 0) {
          // Single error message
          errorsObject.error = errorResponse
        } else if (
          typeof errorResponse === 'string' &&
          errorResponse.length === 0
        ) {
          // No errors
          setSnackbar({
            status: 'error',
            message: 'An unexpected error occurred. Please try again later.',
          })
          return
        } else {
          // Multiple errors
          errorResponse.split(',').forEach((e: string) => {
            const id = e.split(' ')[0]
            if (id) {
              errorsObject[id] = e
            }
          })
        }
        setErrors(errorsObject)
        setSnackbar({
          status: 'error',
          message: 'Please fix the errors.',
        })
      } else {
        setSnackbar({
          status: 'error',
          message: 'An unexpected error occurred. Please try again later.',
        })
      }
    }
  }

  return (
    <>
      <Stack>
        <Box
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '3rem 2.25rem',
            mt: '1.5rem',
            '& *': {
              boxShadow: 'none !important',
            },
            '& > div:not(:last-child)': {
              // background: '#F8FAFC',
            },
            '& div[role="button"]': {
              // background: '#F8FAFC',
            },
            'fieldset, svg': {
              zIndex: 3,
            },
            'fieldset span': {
              fontSize: '0.75rem',
            },
          }}
        >
          <FormField<IProfileForm>
            disabled={isDisabled}
            component={Input}
            form={form}
            value={formData.firstName}
            onChange={(e: any) => {
              form.setValue('firstName', e.target.value)
              setFormData(form.getValues())
            }}
            name="firstName"
            label="First Name"
            rules={{ required: 'First name is required' }}
            error={errors.firstName}
            styles={{
              width: '100%',
              height: '56px',
            }}
          />
          <FormField<IProfileForm>
            disabled={isDisabled}
            component={Input}
            form={form}
            value={formData.lastName}
            onChange={(e: any) => {
              form.setValue('lastName', e.target.value)
              setFormData(form.getValues())
            }}
            name="lastName"
            label="Last Name"
            rules={{ required: 'Last name is required' }}
            error={errors.lastName}
            styles={{
              color: customTheme.palette.secondary.main,
              width: '100%',
              height: '56px',
            }}
          />
          {selectMenus.map((select) => {
            return (
              <FormField<IProfileForm>
                disabled={isDisabled}
                key={select.label}
                component={Select}
                form={form}
                options={select.value.map((item) => ({
                  value: item,
                  label: item,
                }))}
                rules={{ required: `${select.value} is required` }}
                displayName="ReactSelect"
                handleSelect={(value: any) => {
                  form.setValue(select.id, value)
                  setFormData(form.getValues())
                }}
                value={form.watch(select.id)}
                error={errors[select.id]}
                selectLabel={{ name: select.label, id: select.id }}
                name={select.id}
                styles={{
                  color: customTheme.palette.secondary.main,
                  // mt: '36px',
                  height: '56px',
                  '&::placeholder-shown': {
                    pt: '12px',
                  },
                }}
              />
            )
          })}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isDisabled && !isLoading ? (
              <CustomButton
                appearance="primary-soft-default"
                styles={{ height: '48px' }}
                onClick={() => {
                  setIsDisabled(false)
                }}
                type="button"
              >
                Edit Personal Info
              </CustomButton>
            ) : (
              <>
                <CustomButton
                  isLoading={isLoading}
                  type="reset"
                  appearance="cancel-soft-default"
                  styles={{ mr: '12px', height: '48px' }}
                  onClick={handleCancel}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  appearance="primary-soft-default"
                  styles={{ height: '48px' }}
                  isLoading={isLoading}
                  // onClick={onSubmit}
                  type="submit"
                >
                  Submit
                </CustomButton>
              </>
            )}
          </Box>
        </Box>
      </Stack>
      {snackbar && (
        <Snackbar
          open={!!snackbar}
          message={snackbar.message}
          onClose={() => setSnackbar(undefined)}
          type={snackbar.status}
        />
      )}
    </>
  )
}
