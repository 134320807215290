import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Link } from '@mui/material'
import { Socket } from 'socket.io-client'

import { getSocket, useSocket } from 'src/helpers/socketManager'
import { routes } from 'src/router'

import { BillIcon } from './BillIcon'
import { Notification } from './entities/notification'
import { NotificationPanelStyles } from './NotificationIcon.styles'
import { NotificationTile } from './NotificationTile'

export const Notifications: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [count, setCount] = useState(0)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const toggle: MouseEventHandler = useCallback((event: any) => {
    event.stopPropagation()
    setIsOpen((prev) => !prev)

    getSocket()?.emit('notifications:opened')
  }, [])

  const close = useCallback(() => setIsOpen(false), [])

  // auto close logic
  useEffect(() => {
    document.addEventListener('click', close)
    return () => {
      document.removeEventListener('click', close)
    }
  }, [])

  useSocket((socket: Socket) => {
    console.log('listening to notifications')

    socket.on('setNotifications', (data) => {
      setNotifications(data)
      setCount(data.filter((d: Notification) => !d.isOpened).length)
    })

    socket.on('newNotification', (data) => {
      setNotifications((prev) => {
        const newData = [data, ...prev]

        setCount(newData.filter((d: Notification) => !d.isOpened).length)

        return newData
      })
    })
  })

  return (
    <NotificationPanelStyles onClick={toggle} className={isOpen ? 'open' : ''}>
      <div className="heading">
        <CloseIcon className="close-icon"></CloseIcon>

        <BillIcon count={count}></BillIcon>
      </div>

      <Box
        sx={{
          pt: '14px',
        }}
        className={'notification-panel'}
      >
        <ul className={'notifications'}>
          {notifications.map((e) => (
            <NotificationTile key={e.id} notification={e}></NotificationTile>
          ))}
        </ul>
        <Link className="show-all" href={routes.myNotification}>
          Show all notifications
        </Link>
      </Box>
    </NotificationPanelStyles>
  )
}
