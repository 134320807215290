import { configureStore } from '@reduxjs/toolkit'
import { compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers/root-reducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// eslint-disable-next-line max-len
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
