import { FC } from 'react'

import { routes } from 'src/router'

import MediaSection from './MediaSection'

const VideoTutorials: FC = () => {
  return (
    <MediaSection
      title="Video Tutorials"
      apiEndpoint="video-tutorials"
      type="video"
      viewAllLabel="View All Tutorials"
      viewAllRoute={routes.knowledgeBaseVideos}
    />
  )
}

export default VideoTutorials
