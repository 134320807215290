import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { TableCell, IconButton, TableRow, Box } from '@mui/material'

import ExtendedUserRow from '../ExtendableUserRow/ExtendableUserRow'
import { useEffect, useState } from 'react'

const MyTableRow = ({ row, updateOriginalRow, currentUser }: any) => {
  const [isExtended, setIsExtended] = useState(false)
  console.log(row)

  return (
    <>
      {!isExtended && (
        <TableRow key={row.id}>
          <TableCell>{row.original?.username}</TableCell>
          <TableCell>{row.original?.role}</TableCell>
          {currentUser.role === 'globalAdmin' && (
            <TableCell>{row.original?.organization}</TableCell>
          )}{' '}
          {/* <TableCell>{moment(users.date).fromNow()}</TableCell> */}
          <TableCell>
            <StatusCell status={row.original?.status} />
          </TableCell>
          <TableCell
            key={'extendCell'}
            role="cell"
            sx={{
              p: '12px 16px',
            }}
          >
            <IconButton size="small" onClick={() => setIsExtended(!isExtended)}>
              {isExtended ? (
                <KeyboardArrowUp color="action" />
              ) : (
                <KeyboardArrowDown color="action" />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isExtended && (
        <ExtendedUserRow
          currentUser={currentUser}
          key={row.id}
          row={row}
          handleCollapse={() => setIsExtended(!isExtended)}
          updateRowValues={updateOriginalRow}
        />
      )}
    </>
  )
}

export default MyTableRow

const StatusCell = ({ status }: { status: boolean }) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        bgcolor: '#EBF7ED',
        color: '#267D36',
        p: '3px 8px',
        borderRadius: '100px',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
      }}
    >
      {status ? 'Online' : 'Offline'}
    </Box>
  )
}
