import React, { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { Search } from 'src/components/Search'
import { ITableColumns, Table } from 'src/components/Table'
import {
  IconClampRing,
  IconCpm,
  IconDome,
  IconOverTravel,
} from 'src/assets/icons'
import axios from 'axios'
import { useParams } from 'react-router-dom'
/*
{
    accessor: 'factoryName',
    header: 'Factory Asset',
    width: 0,
    isSortable: true,
  }, */
const columns: ITableColumns[] = [
  {
    accessor: 'channelType',
    header: 'Channel Type',
    width: 0,
    // isSortable: true,
  },
  {
    accessor: 'value',
    header: 'Value',
    width: 0,
    // isSortable: true,
  },
  {
    accessor: 'protocol',
    header: 'Protocol',
    width: 0,
    //   isSortable: true,
  },
  {
    accessor: 'unit',
    header: 'Data Units',
    width: 0,
    // isSortable: true,
  },
  {
    accessor: 'description',
    header: 'Description',
    width: 0,
    // isSortable: true,
  },
]

const MachineConnectedDevices: FC = () => {
  //   const handleSearchDebounced = debounce((value) => {
  //     setPagination((prevState) => ({
  //       ...prevState,
  //       searchQuery: value,
  //       searchField: 'bodyMakerName',
  //       page: 1,
  //     }))
  //   }, 500)
  const params = useParams()
  console.log(params)

  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    // get the user's token from local storage
    const user = localStorage.getItem('user')

    const token = user ? JSON.parse(user ?? {}).token : ''

    const eventSource = new EventSource(
      `${axios.defaults.baseURL}/company/channels/${params.bodymakerId}?token=${token}`,
    )

    eventSource.onmessage = (event) => {
      const updatedData = JSON.parse(event.data).channels
      const transformedData = updatedData.map((item: any) => {
        let channelType = <></>
        switch (item.description.toLowerCase()) {
          case 'number':
            channelType = (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    '& path': {
                      stroke: '#095A66',
                    },
                  }}
                  component={SVG}
                  src={IconCpm}
                />
                <Typography
                  sx={{
                    color: '#095A66',
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            )
            break
          case 'distance':
            channelType = (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    '& path': {
                      stroke: '#51810F',
                    },
                  }}
                  component={SVG}
                  src={IconOverTravel}
                />
                <Typography
                  sx={{
                    color: '#51810F',
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            )
            break
          case 'force':
            channelType = (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    '& path': {
                      fill: '#4E32A2',
                    },
                  }}
                  component={SVG}
                  src={IconDome}
                />
                <Typography
                  sx={{
                    color: '#4E32A2',
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            )
        }

        return {
          ...item,
          channelType,
        }
      })

      setData(transformedData)

      eventSource.onerror = (err) => {
        console.log(err)
      }
      return () => {
        eventSource.close()
      }
    }
  }, [params.bodymakerId])

  return (
    <Box
      sx={{
        mt: '48px',
      }}
    >
      {/* <Search
        searchStyles={{
          width: '364px',
          padding: '0.3rem 3rem 0.3rem 0',
        }}
        selectLabel={{ name: 'Filter', id: 'filter' }}
        styles={{ width: 'auto' }}
        // onChange={(e) => handleSearchDebounced(e.target.value)}
        hasFilters={true}
        onFilterChange={(e: any) => {
          //   onFilterChange(e)
        }}
        // options={filters}
        buttonTitle="Edit Channels"
        isMultiFilter={true}
        selectStyles={{
          width: '124px',
          backgroundColor: '#F5F7F9',
          borderRadius: '4px',
        }}
      /> */}
      <Box
        sx={{
          mt: '24px',
        }}
      >
        <Table
          tdStyles={{
            borderBottom: 'none',
          }}
          containerStyles={{
            '& tr': {
              borderBottom: 'none',
            },
            paddingBottom: '40px',
          }}
          loading={false}
          columns={columns}
          data={data}
        />
      </Box>
    </Box>
  )
}

export default MachineConnectedDevices
