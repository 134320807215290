import { PortalActionTypes } from 'src/store/action-types'
import { PortalActions } from 'src/store/actions'
import { RootState } from 'src/store/reducers'

export const darkMode = (state: RootState): any => state.portalReducer.darkMode

const initialState = {
  darkMode: localStorage.getItem('darkMode') === 'true',
}

const reducer = (state: any = initialState, action: PortalActions): any => {
  switch (action.type) {
    case PortalActionTypes.SET_THEME:
      localStorage.setItem('theme', action.payload)
      return {
        ...state,
        darkMode: action.payload === 'dark',
      }
    default:
      return state
  }
}

export default reducer
