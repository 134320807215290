import styled from '@emotion/styled'

export const NotificationPanelStyles = styled.div`
  --transition-duration: 0.2s;

  position: relative;
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-self: start;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;

  transition: var(--transition-duration) ease-in-out;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;
    transition: var(--transition-duration) ease-in-out;
  }

  .close-icon {
    width: 0px;
    transition: width var(--transition-duration) ease-in-out;
  }

  .notification-panel {
    position: absolute;
    width: 100%;
    top: 100%;
    transform: scaleY(0);
    transform-origin: top;
    background-color: white;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    z-index: 1000;
    transition: calc(var(--transition-duration) / 2) ease-in-out;

    .notifications {
      max-height: 50vh;
      overflow: auto;
    }

    .show-all {
      text-decoration: none;
      float: right;
      padding: 5px 10px;
      font-size: 0.8rem;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      color: #424242;
    }

    .text {
      font-size: 12px;
      color: #616161;
    }

    ul {
      list-style-type: none;

      li {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        align-items: center;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.open {
    width: 310px;
    border-radius: 8px 8px 0 0;

    .heading {
      padding: 5px 10px;
    }

    .close-icon {
      width: 40px;
    }

    .notification-panel {
      transform: scaleY(1);
    }
  }
`
