import styled from '@emotion/styled'
import { RadioProps, Radio } from '@mui/material'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: '#f5f8fa',
  outline: '1px solid #6A7178',

  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  //   'input:hover ~ &': {
  //     backgroundColor: '#f5f8fa',
  //   },
  //   'input:disabled ~ &': {
  //     boxShadow: 'none',
  //   },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#1068EB',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
})

function BpRadio(props: RadioProps): JSX.Element {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  )
}

export default BpRadio
