import { FC } from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTheme, Box } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate, useLocation } from 'react-router-dom'

import customTheme from 'src/themes/themeLight'

interface BreadcrumbProps {
  finalText?: string
  ignoreFinalText?: boolean
  canGoBack?: boolean
  breadcrumbs?: Array<{
    label: string
    href?: string
    color?: string
  }>
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  finalText,
  ignoreFinalText,
  canGoBack = true,
  breadcrumbs,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme<typeof customTheme>()

  // Split the path into individual segments
  const pathSegments = location.pathname
    .split('/')
    .filter((segment) => segment !== '')
  let breadcrumbLinks: JSX.Element[] | JSX.Element
  if (breadcrumbs) {
    breadcrumbLinks = breadcrumbs.map((breadcrumb) => {
      if (breadcrumb.href) {
        return (
          <Link
            underline="hover"
            key={breadcrumb.label}
            color={breadcrumb.color ?? theme.palette.action.main}
            fontSize="0.875rem"
            textTransform={'capitalize'}
            href={breadcrumb.href}
            onClick={(event) => {
              event.preventDefault()
              navigate(breadcrumb.href ?? '/')
            }}
          >
            {breadcrumb.label}
          </Link>
        )
      } else {
        return (
          <Typography
            textTransform={'capitalize'}
            key={breadcrumb.label}
            color={breadcrumb.color ?? '#ADB5BD'}
            fontSize="0.875rem"
          >
            {breadcrumb.label}
          </Typography>
        )
      }
    })
  } else {
    // Create an array of breadcrumb links using the path segments
    breadcrumbLinks = pathSegments.map((segment, index) => {
      const href = `/${pathSegments.slice(0, index + 1).join('/')}`
      const returnedJSX = !canGoBack ? (
        <Typography
          textTransform={'capitalize'}
          key={segment}
          color="#ADB5BD"
          fontSize="0.875rem"
        >
          {segment.replaceAll('-', ' ')}
        </Typography>
      ) : (
        <Link
          underline="hover"
          key={segment}
          color="#ADB5BD"
          fontSize="0.875rem"
          textTransform={'capitalize'}
          href={href}
          onClick={(event) => {
            event.preventDefault()
            navigate(href)
          }}
        >
          {segment.replaceAll('-', ' ')}
        </Link>
      )
      return returnedJSX
    })

    breadcrumbLinks.pop()
    // Add the last or second to last breadcrumb segment as a plain text Typography element
    if (ignoreFinalText) {
      // removes the 2nd to last element as well to readd it with color
      breadcrumbLinks.pop()
      breadcrumbLinks.push(
        <Typography
          textTransform={'capitalize'}
          key={pathSegments[pathSegments.length - 2]}
          color={theme.palette.action.main}
          fontSize="0.875rem"
        >
          {pathSegments[pathSegments.length - 2].replace('-', ' ')}
        </Typography>,
      )
    } else {
      // Add the last breadcrumb segment as a plain text Typography element
      breadcrumbLinks.push(
        <Typography
          textTransform={'capitalize'}
          key={pathSegments[pathSegments.length - 1]}
          color={theme.palette.action.main}
          fontSize="0.875rem"
        >
          {finalText ?? pathSegments[pathSegments.length - 1].replace('-', ' ')}
        </Typography>,
      )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        bgcolor: '#fff',
        color: '#ADB5BD',
        fontSize: '0.875rem',
        py: '8px',
        px: '16px',
        height: '48px',
        justifyContent: 'center',
        borderRadius: '0.25rem',
        boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack spacing={2}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="small"
              sx={{
                color: '#ADB5BD',
              }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbLinks}
        </Breadcrumbs>
      </Stack>
    </Box>
  )
}

export default Breadcrumb
