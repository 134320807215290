import { FC, useEffect, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import SimpleBarReact from 'simplebar-react'

import 'simplebar-react/dist/simplebar.min.css'
import '../MapAside/styles.css'

import {
  IconChevronDown,
  IconColored3dCube,
  IconColoredSitemap,
} from 'src/assets/icons'
import { CustomButton } from 'src/ui/Button'

import { IFactory } from '../../types'
import { ProductionAverage } from '../ProductionAverage'

interface FactoryCardProps {
  id?: number
  onClickBack: (id: number) => void
}

export const FactoryCard: FC<FactoryCardProps> = ({ id, onClickBack }) => {
  const [factoryData, setFactoryData] = useState<IFactory>()
  const [numOfBodyMakers, setNumOfBodyMakers] = useState(0)
  const [status, setStatus] = useState({
    synapseNormal: 0,
    synapseWarning: 0,
    synapseFailure: 0,
    synapseFault: 0,
    synapseUnknown: 0,
  })
  const [sorting, setSorting] = useState<{
    column: string
    order: 'asc' | 'desc'
  }>({
    column: 'name',
    order: 'asc',
  })

  // const [numOfLines, setNumOfLines] = useState(0)
  useEffect(() => {
    console.log('getting factory data')
    const fetchData = async (): Promise<void> => {
      const response = await axios.get(`/company/map-data/${id}`, {
        params: {
          sortColumn: sorting?.column,
          sortOrder: sorting?.order,
        },
      })

      setFactoryData(response.data.factory)

      setStatus(response.data.synapseStatusThresholds)
      setNumOfBodyMakers(response.data.totalBodyMakers)
    }

    if (id) {
      fetchData()
        .then(() => {})
        .catch((error) => {
          console.log(error.message)
        })
    } else {
      setFactoryData(undefined)
    }
  }, [id, sorting])

  const handleSorting = (column: string): void => {
    setSorting((prevState) => {
      if (prevState.column === column) {
        return {
          column,
          order: prevState.order === 'asc' ? 'desc' : 'asc',
        }
      }
      return {
        column,
        order: 'asc',
      }
    })
  }
  return (
    <SimpleBarReact
      style={{
        position: 'absolute',
        zIndex: 10,
        height: '100%',
        width: '100%',
        padding: '24px 32px',
        backgroundColor: '#FFF',
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
        top: 0,
        left: factoryData ? 0 : '-100%',
      }}
      autoHide={false}
    >
      {factoryData && (
        <>
          <CustomButton
            appearance="primary-soft-default"
            styles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '40px',
              minHeight: '40px',
              p: '0px !important',
              borderRadius: '50%',
              '& > div': {
                margin: '0px !important',
                display: 'flex',
              },
            }}
            onClick={() => {
              id && onClickBack(id)
              setFactoryData(undefined)
            }}
          >
            <SVG
              src={IconChevronDown}
              style={{ fill: '#FFF', transform: 'rotate(90deg)' }}
            />
          </CustomButton>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              mt: '30px',
              mb: '4px',
              color: '#272B30',
            }}
          >
            {factoryData.name}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: '#ADB5BD' }}>
            {companyType}
          </Typography> */}
          <Typography variant="body2" sx={{ color: '#4F575E', mt: '16px' }}>
            {factoryData.address}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              p: '16px',
              mt: '48px',
              border: '1px solid #CED4DA',
              bgcolor: '#F1F3F5',
              gap: '28px',
              '&>div': {
                borderRight: '1px solid #CED4DA',
                pr: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              },
              '&>div:last-child': {
                border: 'none',
                pr: 0,
              },
            }}
          >
            <Box>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '26px',
                    lineHeight: '32px',
                    fontWeight: 700,
                    color: '#272B30',
                  }}
                >
                  {factoryData.lines?.length ?? '0'}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: 'secondary.main',
                  }}
                >
                  Total Lines
                </Typography>
              </Stack>
              <SVG
                src={IconColoredSitemap}
                style={{
                  marginLeft: '8px',
                }}
              />
            </Box>
            <Box>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '26px',
                    lineHeight: '32px',
                    fontWeight: 700,
                    color: '#272B30',
                  }}
                >
                  {numOfBodyMakers}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: 'secondary.main',
                  }}
                >
                  Total Bodymakers
                </Typography>
              </Stack>
              <SVG
                src={IconColored3dCube}
                style={{ marginLeft: '8px', width: '50px', height: '50px' }}
              />
            </Box>
          </Box>
          {factoryData.lines?.length && (
            <ProductionAverage
              lines={factoryData.lines}
              status={status}
              handleSorting={handleSorting}
            />
          )}
        </>
      )}
    </SimpleBarReact>
  )
}
