import { FC, useState } from 'react'

import { debounce } from 'lodash'

import { routes } from 'src/router'
import { IOption } from 'src/ui/Select'

import BaseGrid from './BaseGrid'
import BaseHeader from './BaseHeader'

const Videos: FC = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useState<IOption[]>([])
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const endpoint = '/video-tutorials'
  const type = 'video'

  const handleSearchDebounced = debounce((value) => {
    setSearchQuery(value)
  }, 500)

  const handleFilters = (filter: any): void => {
    setSelectedFilters(filter)
  }

  const setExistingFilters = (existingFilters: string[]): void => {
    const mappedFilters = existingFilters.map((filter) => {
      return {
        label: filter.split('')[0].toUpperCase() + filter.slice(1),
        value: filter,
      }
    })
    setFilters(mappedFilters)
  }

  return (
    <>
      <BaseHeader
        title="Videos"
        subtitle="In this section you can find out how to leverage the use of your Pride Platform."
        backButtonLink={routes.knowledgeBase}
        onSearchChange={(searchTerm) => handleSearchDebounced(searchTerm)}
        onFilterChange={handleFilters}
        filters={filters}
      />

      <BaseGrid
        searchQuery={searchQuery}
        endpoint={endpoint}
        type={type}
        filters={selectedFilters}
        getFilters={setExistingFilters}
      />
    </>
  )
}

export default Videos
