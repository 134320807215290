import { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import axios from 'axios'

import { PageHeading } from 'src/components/PageHeading'
import { MediaCardProps } from 'src/ui/Card/MediaCard'
import MediaGrid from 'src/ui/Card/MediaGrid'

import ViewAllChip from './ViewAllChip'

interface Props {
  title: string
  limit?: number
  apiEndpoint: string
  type: 'article' | 'video'
  viewAllLabel: string
  viewAllRoute: string
}

const MediaSection: FC<Props> = ({
  title,
  limit = 3,
  apiEndpoint,
  type,
  viewAllLabel,
  viewAllRoute,
}) => {
  const [loading, setLoading] = useState(true)
  const [media, setMedia] = useState<MediaCardProps[]>([])
  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PROD

  useEffect(() => {
    const fetchMedia = async (): Promise<void> => {
      const res = await axios.get(
        `${apiEndpoint}?limit=${limit}`,
      )
      setMedia(res.data.items)
      setLoading(false)
    }
    fetchMedia().then().catch(console.log)
  }, [limit])

  return (
    <Box sx={{ mt: '5.375rem' }}>
      <PageHeading title={title} />
      <Box sx={{ my: '2.25rem' }}>
        <MediaGrid media={media} type={type} loading={loading} />
      </Box>
      <ViewAllChip buttonLabel={viewAllLabel} navigateTo={viewAllRoute} />
    </Box>
  )
}

export default MediaSection
