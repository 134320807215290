import { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import SimpleBarReact from 'simplebar-react'

import 'simplebar-react/dist/simplebar.min.css'
import './styles.css'

import { IFactory } from '../../types'
import { FactoryCard } from '../FactoryCard'
import { FactoryItem } from '../FactoryItem'
import { useParams } from 'react-router-dom'

// const mockedData: IFactory[] = [
//   {
//     id: 1,
//     name: 'Pride Factory ',
//     companyType: 'Software Company',
//     bodyMakersCount: 1235,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 2,
//     name: 'Pride Factory ',
//     companyType: 'Civil Engineering Company',
//     bodyMakersCount: 1342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 3,
//     name: 'Pride Factory ',
//     companyType: 'Pharmacy Company',
//     bodyMakersCount: 18658,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 4,
//     name: 'Pride Factory ',
//     companyType: 'Civil Engineering Company',
//     bodyMakersCount: 1342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },

//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 5,
//     name: 'Pride Factory ',
//     companyType: 'Pharmacy Company',
//     bodyMakersCount: 2342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 6,
//     name: 'Pride Factory ',
//     companyType: 'Pharmacy Company',
//     bodyMakersCount: 4342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },

//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 7,
//     name: 'Pride Factory ',
//     companyType: 'Civil Engineering Company',
//     bodyMakersCount: 6342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },

//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 8,
//     name: 'Pride Factory ',
//     companyType: 'Software Company',
//     bodyMakersCount: 2342,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },

//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 9,
//     name: 'Pride Factory ',
//     companyType: 'Software Company',
//     bodyMakersCount: 132,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },

//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//       {
//         name: 'Line 9',
//         rate: 1000,
//       },
//       {
//         name: 'Line 10',
//         rate: 1000,
//       },
//     ],
//   },
//   {
//     id: 10,
//     name: 'Pride Factory ',
//     companyType: 'Software Company',
//     bodyMakersCount: 13,
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     address: `Kamiyacho MT Bldg 14th Floor 3-202 Toranomon,
//        Minato-ku, Tokyo, 105-0001`,
//     lines: [
//       {
//         name: 'Line 1',
//         rate: 2345,
//       },
//       {
//         name: 'Line 2',
//         rate: 1000,
//       },
//       {
//         name: 'Line 3',
//         rate: 0,
//       },
//       {
//         name: 'Line 4',
//         rate: 2345,
//       },
//       {
//         name: 'Line 5',
//         rate: 1000,
//       },
//       {
//         name: 'Line 6',
//         rate: 0,
//       },
//       {
//         name: 'Line 7',
//         rate: 0,
//       },
//       {
//         name: 'Line 8',
//         rate: 2345,
//       },
//     ],
//   },
// ]

interface MapAsideProps {
  data?: IFactory[]
  activeMarkerId?: number
  changeMarker?: (id?: number) => void
}

export const MapAside: FC<MapAsideProps> = (props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '458px',
        width: '100%',
        bgcolor: '#F1F3F5',
      }}
    >
      <SimpleBarReact
        autoHide={false}
        style={{
          height: '100%',
          padding: '24px 40px 24px 32px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#272B30',
            ml: '16px',
            mb: '24px',
          }}
        >
          {`Listed ${props.data ? props.data.length : 0}/${
            props.data ? props.data.length : 0
          } factories`}
        </Typography>
        {props.data?.map((factory, i) => (
          <FactoryItem
            data={factory}
            key={factory.id}
            onClick={() => props.changeMarker?.(factory.id)}
          />
        ))}
      </SimpleBarReact>
      <FactoryCard
        id={props.activeMarkerId}
        onClickBack={(id: number) => {
          props.changeMarker && props.changeMarker(undefined)
        }}
      />
    </Box>
  )
}
