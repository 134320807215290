import { PaletteMode } from '@mui/material'

import { theme } from 'src/themes/base'

const customTheme = {
  ...theme,
  typography: {
    ...theme.typography,
    allVariants: {
      ...theme.typography.allVariants,
      color: '#d4d4d4',
    },
  },
  components: {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: {
        svg: {
          path: {
            fill: 'white',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:webkitAutofill': {
            webkitBoxShadow: '0 0 0 100px #121212 inset',
            webkitTextFillColor: '#fff',
          },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        input: {
          '&:webkitAutofill': {
            webkitBoxShadow: '0 0 0 100px #121212 inset',
            webkitTextFillColor: '#fff',
          },
        },
      },
    },
  },
  palette: {
    ...theme.palette,
    mode: 'dark' as PaletteMode,
    primary: {
      main: '#64ffda',
    },
    action: {
      main: 'red',
    },
    secondary: {
      main: '#ff80ab',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    static: {
      main: '#000',
    },
  },
}

export default customTheme
