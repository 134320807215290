import { Dispatch } from 'redux'

import { PortalActionTypes } from 'src/store/action-types'
import { PortalActions } from 'src/store/actions'

export const toggleColorMode = (
  theme: 'dark' | 'light',
): ((dispatch: Dispatch<PortalActions>) => void) => {

  return (dispatch: Dispatch<PortalActions>) => {
    dispatch({
      type: PortalActionTypes.SET_THEME,
      payload: theme,
    })
  }
}
