import { FC, useContext, useState } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Header } from 'src/components/Header'
import { Sidebar } from 'src/modules/Sidebar'
import { GeneralLayout } from 'src/ui/GeneralLayout'
import 'react-toastify/dist/ReactToastify.css'

export const Root: FC = () => {
  const largeScreen = useMediaQuery('(min-width: 1200px)')
  const [isAsideVisible, setIsAsideVisible] = useState<boolean>(largeScreen)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div id="notification-root"></div>
      <div id="loader-root"></div>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <Sidebar
          setIsAsideVisible={setIsAsideVisible}
          isAsideVisible={isAsideVisible}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
        >
          <GeneralLayout isAsideVisible={isAsideVisible}>
            <Header
              setIsAsideVisible={setIsAsideVisible}
              isAsideVisible={isAsideVisible}
            />
            <Outlet />
            <ToastContainer />
          </GeneralLayout>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}
