import { FC } from 'react'

import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'

const TableSkeleton: FC<{ limit?: number; columnCount: number }> = ({
  limit = 5,
  columnCount,
}) => {
  const rows = Array.from({ length: limit }, (_, index) => index + 1)
  const columns = Array.from({ length: columnCount }, (_, index) => index + 1)
  return (
    <TableBody
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '8px',
        backgroundColor: '#FFF',
        filter: 'drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.1))',
      }}
    >
      {rows.map((row) => (
        <TableRow key={row}>
          {columns.map((_, i) => (
            <TableCell key={i}>
              <Skeleton height={40} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

export default TableSkeleton
