import { FC } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import MediaCard, { MediaCardProps } from './MediaCard'

interface MediaGridProps {
  media: MediaCardProps[]
  query?: string
  type: 'video' | 'article'
  loading?: boolean
  skeletonCount?: number
  skeletonProps?: {
    width?: string | number
    height?: string | number
    mb?: string | number
  }
}

const MediaGrid: FC<MediaGridProps> = ({
  media,
  query,
  type,
  loading = false,
  skeletonCount = 3,
  skeletonProps = {},
}) => {
  const showSkeleton = loading || media.length === 0

  if (!loading && media.length === 0) {
    if (query) {
      return (
        <Box sx={{ textAlign: 'center', mt: '2rem' }}>
          <h3>
            No {type}s found for &quot;{query}&quot;. Try searching for
            something else.
          </h3>
        </Box>
      )
    } else {
      return (
        <Box sx={{ textAlign: 'center', mt: '2rem' }}>
          <h3>No {type}s found.</h3>
        </Box>
      )
    }
  }

  return (
    <Grid container spacing={4}>
      {[...Array(skeletonCount)].map(
        (_, i) =>
          showSkeleton && (
            <Grid item xs={12} md={6} lg={4} key={i}>
              <Box
                sx={{
                  mb: skeletonProps.mb ?? '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <>
                  <Skeleton
                    width={skeletonProps.width ?? '100%'}
                    height={skeletonProps.height ?? 264}
                    variant="rectangular"
                    sx={{ mb: '1rem' }}
                  />
                  <Skeleton
                    width="50%"
                    height={20}
                    variant="text"
                    sx={{ mb: '0.5rem' }}
                  />
                  <Skeleton width="80%" height={20} variant="text" />
                </>
              </Box>
            </Grid>
          ),
      )}
      {!showSkeleton &&
        media.map((mediaCard) => (
          <MediaCard mediaCard={{ ...mediaCard, type }} key={mediaCard.id} />
        ))}
    </Grid>
  )
}

export default MediaGrid
