import React, { FC, useEffect, useState } from 'react'

import { Grid, Card, CardContent, Box, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import { useBeforeUnload, useParams } from 'react-router-dom'

import {
  IconClampRing,
  IconCpm,
  IconDome,
  IconOverTravel,
} from 'src/assets/icons'
import { ImageMachine } from 'src/assets/images'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import { Image } from 'src/ui/Image'

import MachineCardToolTip from './MachineCardToolTip'

const MachineDetails: FC<{
  averages: any
  statuses: any
  isLoading: boolean
  lastUpdated?: {
    dome: Date | null
    overtravel: Date | null
    clampRing: Date | null
  }
}> = ({ averages, statuses, isLoading, lastUpdated }) => {
  const [domeValue, setDomeValue] = useState('Loading...')
  const [overTravelValue, setOverTravelValue] = useState('Loading...')
  const [clampRingValue, setClampRingValue] = useState('Loading...')
  const [cpmValue, setCpmValue] = useState('Loading...')
  const [cpmNumber, setCpmNumber] = useState(0)
  const { plantId, lineId, bodymakerId } = useParams()
  const [eventSource, setEventSource] = useState<EventSource | null>()

  useBeforeUnload(() => {
    eventSource?.close()
  })

  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    const token = user?.token

    // get cpm from an event source
    const newEventSource = new EventSource(
      `${axios.defaults.baseURL}company/factories/${plantId}/lines/${lineId}/bodymakers/${bodymakerId}/cpm?token=${token}`,
      { withCredentials: true },
    )

    setEventSource(newEventSource)

    return () => {
      newEventSource.close()
    }
  }, [bodymakerId, lineId, plantId])

  useEffect(() => {
    eventSource?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)

      setCpmValue(`${data.cpm ?? 0} CPM`)
      setCpmNumber(data.cpm ?? 0)
    })

    eventSource?.addEventListener('open', (event) => {
      setCpmValue('0 CPM')
    })

    eventSource?.addEventListener('error', (event) => {
      console.log('error', event)
    })
    return () => {
      eventSource?.close()
    }
  }, [eventSource])

  useEffect(() => {
    if (!isLoading) {
      setDomeValue(`${averages.dome ?? '-'} lbf`)
      setOverTravelValue(`${averages.overtravel ?? '-'} in`)
      setClampRingValue(`${averages.clampRing ?? '-'} lbf`)
      // setCpmValue(`${averages.cpm ?? '-'} CPM`)
    } else {
      setDomeValue('Loading...')
      setOverTravelValue('Loading...')
      setClampRingValue('Loading...')
      // setCpmValue('Loading...')
    }
  }, [isLoading, averages])
  return (
    <Grid container columnSpacing={4} mt="16px">
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={4}
        spacing={2}
        container
        direction="column"
      >
        <MachineCard
          title="Dome"
          value={domeValue}
          icon={IconDome}
          status={statuses.dome}
          fill={true}
          lastUpdated={
            lastUpdated?.dome
              ? convertTimestampToLocal(lastUpdated?.dome.toLocaleString())
              : 'N/A'
          }
        />
        <MachineCard
          title="Over Travel"
          value={overTravelValue}
          icon={IconOverTravel}
          status={statuses.overtravel}
          lastUpdated={
            lastUpdated?.overtravel
              ? convertTimestampToLocal(
                  lastUpdated?.overtravel.toLocaleString(),
                )
              : 'N/A'
          }
        />
        <MachineCard
          title="Clamp Ring"
          value={clampRingValue}
          icon={IconClampRing}
          status={statuses.clampRing}
          lastUpdated={
            lastUpdated?.clampRing
              ? convertTimestampToLocal(lastUpdated?.clampRing.toLocaleString())
              : 'N/A'
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={8}>
        <Box
          sx={{
            display: 'grid',
            gap: '16px',
          }}
        >
          <Card
            sx={{
              padding: '16px 24px 33px',
              boxShadow: '0px 6px 8px 0px #0000001A',
              maxHeight: '340px',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                color: '#000',
              }}
            >
              Overlay
            </Typography>
            <CardContent>
              <Box
                sx={{
                  position: 'relative',
                  margin: '0 auto',
                  width: 'fit-content',
                }}
              >
                <Image
                  src={ImageMachine}
                  styles={{
                    width: '426px',
                  }}
                />

                <MachineCardToolTip
                  title="Over Travel"
                  value={overTravelValue}
                  icon={IconOverTravel}
                  top="-22px"
                  left="34%"
                  status={statuses.overtravel}
                />
                <MachineCardToolTip
                  title="Clamp Ring"
                  icon={IconClampRing}
                  top="11%"
                  left="-8%"
                  value={clampRingValue}
                  status={statuses.clampRing}
                />
                <MachineCardToolTip
                  title="Dome"
                  value={domeValue}
                  icon={IconDome}
                  top="50%"
                  left="3%"
                  status={statuses.dome}
                />
                <MachineCardToolTip
                  title="CPM"
                  value={cpmValue}
                  icon={IconCpm}
                  top="68%"
                  left="79%"
                  status={statuses.cpm}
                />
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              boxShadow: '0px 6px 8px 0px #0000001A',
              height: '162px',
              padding: '16px 24px 24px',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                marginBottom: '32px',
                color: '#000',
              }}
            >
              CPM
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '440px',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: '#dfdfdf',
                    height: '36px',
                    borderRadius: '4px',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: `${(Math.min((cpmNumber ?? 0), 400) / 400) * 100}%`,
                      height: '100%',
                      background: '#216eb1',
                      borderRadius: '4px',
                      transition: 'width 0.5s ease-in-out',
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '16px',
                    color: '#ADB5BD',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#ADB5BD',
                      fontSize: '12px',
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        width: '1px',
                        background: '#E9ECEF',
                        right: '75%',
                        height: '47px',
                        top: '-56px',
                      },
                    }}
                  >
                    0 CPM
                  </Typography>
                  <Typography
                    sx={{
                      color: '#ADB5BD',
                      fontSize: '12px',
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        width: '1px',
                        background: '#E9ECEF',
                        right: '50%',
                        height: '47px',
                        top: '-56px',
                      },
                    }}
                  >
                    200 CPM
                  </Typography>
                  <Typography
                    sx={{
                      color: '#ADB5BD',
                      fontSize: '12px',
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        width: '1px',
                        background: '#E9ECEF',
                        left: '75%',
                        height: '47px',
                        top: '-56px',
                      },
                    }}
                  >
                    400 CPM
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: '#272B30',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: 'fit-content',
                  marginLeft: '50px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '26px',
                    fontWeight: 500,
                    color: '#fff',
                  }}
                >
                  {cpmValue ?? '-'}{' '}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MachineDetails

interface MachineCardProps {
  title: string
  value: string
  icon: string
  status: 'normal' | 'warning' | 'critical'
  fill?: boolean
  lastUpdated?: string
}

const MachineCard: FC<MachineCardProps> = ({
  title,
  value,
  icon,
  status,
  fill = false,
  lastUpdated,
}) => {
  const statusColor =
    status === 'normal'
      ? '#29823B'
      : status === 'warning'
      ? '#E9A100'
      : '#EB5757'

  return (
    <Grid item>
      <Card
        sx={{
          padding: '16px 24px 42px',
          boxShadow: '0px 6px 8px 0px #0000001A',
          height: '162px',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            marginBottom: '32px',
            color: '#000',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component={SVG}
            src={icon}
            sx={{
              '& path': {
                fill: fill ? statusColor : undefined,
                stroke: fill ? undefined : statusColor,
              },
              marginRight: '16px',
              width: '40px',
              height: '40px',
            }}
          />
          <Box>
            <Box
              sx={{
                backgroundColor: statusColor,
                borderRadius: '4px',
                width: 'fit-content',
                padding: '6px 12px',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '26px',
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {value.split(' ')[0]}
                </span>{' '}
                {value.split(' ')[1]}
              </Typography>
            </Box>
            <Typography>Last updated: {lastUpdated ?? 'N/A'}</Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}
