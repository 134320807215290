/* eslint-disable eqeqeq */
import React, { FC, SetStateAction, useEffect, useState } from 'react'

import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import axios from 'axios'
import { motion } from 'framer-motion'
import SVG from 'react-inlinesvg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  Icon3DCube,
  IconBuildingFactory,
  IconChevronDown,
  IconSitemap,
} from 'src/assets/icons'
import { ImageSynapseLogo } from 'src/assets/images'

import { DrawerLogo } from './components/DrawerLogo'
import SideBarButton from './components/SideBarButton'
import { routes } from 'src/router'
import { generateUUID } from 'src/helpers/generateUUID'
 

interface SidebarProps {
  isAsideVisible: boolean
  setIsAsideVisible?: (value: SetStateAction<boolean>) => void
}

const sideBarWidth = 308

export const Sidebar: FC<SidebarProps> = ({
  isAsideVisible,
  setIsAsideVisible,
}) => {
  const navigate = useNavigate()
  const {
    bodymakerId,
    lineId,
    plantId: selectedFactory,
  } = useParams<{
    plantId: string
    lineId: string
    bodymakerId: string
  }>()

  console.log(
    'selectedFactory',
    selectedFactory,
    'lineId',
    lineId,
    'bodymakerId',
    bodymakerId,
  )

  const [sidebarData, setSidebarData] = useState<any[]>([])

  useEffect(() => {
    void (async () => {
      const response = await axios.get('/company/sidebar-data')
      setSidebarData(response.data.factories)
    })()
  }, [])

  //  useEffect(() => {
  //   if (pathname.includes('lines')) return
  //   setSelectedFactory('')
  //   setSelectedLine('')
  //   setSelectedBodymaker('')
  // }, [pathname])

  // useEffect(() => {
  //   if (!selectedFactory) return

  //   if (selectedBodymaker) {
  //     navigate(
  //       `factories/${selectedFactory}/lines/${selectedLine}/machine/${selectedBodymaker}`,
  //     )
  //   } else if (selectedLine) {
  //     navigate(`factories/${selectedFactory}/lines/${selectedLine}`)
  //   } else {
  //     navigate(`${routes.fleetMap}/${selectedFactory}`)
  //   }
  // }, [selectedLine, selectedBodymaker, selectedFactory])

  return (
    <Stack sx={{ position: 'relative' }}>
      {setIsAsideVisible && (
        <Box position="sticky" top={25} zIndex={100}>
          <motion.div
            onClick={() => setIsAsideVisible((prev) => !prev)}
            animate={isAsideVisible ? 'open' : 'close'}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              borderRadius: '100%',
              padding: '10px',
              lineHeight: 0,
              right: '8px',
            }}
            variants={{
              open: {
                right: '8px',
                color: 'black',
                background: '#fff0',
              },
              close: {
                right: '-25px',
                transform: 'rotate(180deg)',
                background: '#1068EB',
                color: 'white',
              },
            }}
          >
            <ArrowBackIosNewOutlined />
          </motion.div>
        </Box>
      )}

      <Stack
        component="aside"
        sx={{
          position: 'sticky',
          top: 0,
          transition: 'all 200ms ease-in-out',
          overflow: 'hidden',
          minHeight: '100vh',
          zIndex: 0,
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
          minWidth: isAsideVisible ? sideBarWidth : 0,
          width: isAsideVisible ? sideBarWidth : 0,
          p: isAsideVisible ? '24px' : 0,
          bgcolor: '#fff',
        }}
      >
        <DrawerLogo src={ImageSynapseLogo} alt="Synapse logo" />
        <Box
          sx={{
            width: '100%',
            height: '1px',
            mt: '40px',
            mb: '32px',
            bgcolor: '#CED4DA',
          }}
        />
        {sidebarData.map((item, i) => (
          <React.Fragment key={i}>
            <Box
              onClick={() => navigate(`${routes.fleetMap}/${item.id}`)}
              component={'button'}
              sx={{
                background:
                  selectedFactory == item.id ? '#1068EB' : 'transparent',
                width: '100%',
                mb: '8px',
                borderRadius: '4px',
                padding: '9px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: selectedFactory == item.id ? '#0054a4' : '#E9ECEF',
                  color: selectedFactory == item.id ? '#4F575E' : '#FFFFFF',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'calc(100% - 24px)',
                  '& path': {
                    fill: selectedFactory == item.id ? '#fff' : '#4F575E',
                    stroke: selectedFactory == item.id ? '#fff' : '#4F575E',
                  },
                }}
              >
                <SVG
                  src={IconBuildingFactory}
                  style={{
                    flexShrink: 0,
                    width: '24px',
                    height: '24px',
                  }}
                />
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    ml: '8px',
                    color: selectedFactory == item.id ? '#fff' : '#4F575E',
                    fontWeight: 500,
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              <SVG
                src={IconChevronDown}
                style={{
                  flexShrink: 0,
                  fontWeight: 300,
                  fill: selectedFactory == item.id ? '#fff' : '#4F575E',
                  stroke: 'transparent',
                  rotate: selectedFactory == item.id ? '180deg' : '0deg',
                }}
              />
            </Box>
            <Stack
              sx={{
                maxHeight: selectedFactory == item.id ? '400px' : '0',
                transition: 'max-height 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                overflow: 'hidden',
              }}
            >
              {item.lines.map((line: any) => (
                <React.Fragment key={generateUUID()}>
                  <SideBarButton
                    key={line.id}
                    isFirst={line.id == item.lines[0].id}
                    isLast={line.id == item.lines[item.lines.length - 1].id}
                    itemIcon={IconSitemap}
                    itemName={line.name}
                    selected={lineId == line.id}
                    onClick={() =>
                      navigate(`factories/${item.id}/lines/${line.id}`)
                    }
                  />
                  {lineId == line.id && (
                    <>
                      {line.bodymakers.map((bodymaker: any) => (
                        <Box
                          sx={{
                            my: 1,
                          }}
                          key={bodymaker.id}
                        >
                          <SideBarButton
                            indentation="large"
                            key={bodymaker.id}
                            isFirst={bodymaker.id == line.bodymakers[0].id}
                            isLast={
                              bodymaker.id ==
                              line.bodymakers[line.bodymakers.length - 1].id
                            }
                            isExpandable={false}
                            itemIcon={Icon3DCube}
                            itemName={bodymaker.name}
                            selected={bodymakerId == bodymaker.id}
                            onClick={() => {
                              navigate(
                                `factories/${item.id}/lines/${line.id}/machine/${bodymaker.id}`,
                              )
                            }}
                          />
                        </Box>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  )
}

export default Sidebar
