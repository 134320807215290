import { MouseEvent, ReactElement, useState } from 'react'

import { Box, SxProps, Theme, Button } from '@mui/material'

const defaultButtonStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: '#0a1018',
  height: '38px',
  width: '120px',
  borderLeft: '1px solid #1068EB',
  borderRadius: 0,
  '&:first-of-type': {
    border: 'none',
  },
  '&:hover': {
    bgcolor: '#1068EB',
    opacity: '0.1',
  },
}

const defaultContainerStyles: SxProps<Theme> = {
  display: 'flex',
  overflow: 'hidden',
  backgroundColor: '#FFF',
  border: '1px solid #1068EB',
  borderRadius: '4px',
  width: 'min-content',
}

interface TabsRowProps<T> {
  tabsArr: T[]
  active?: T
  onClick?: (tab: T) => void
  tabsStyles?: SxProps<Theme> | undefined
  styles?: SxProps<Theme> | undefined
}

export const TabsRow = <TNames extends string>({
  tabsArr,
  onClick,
  active,
  tabsStyles,
  styles,
}: TabsRowProps<TNames>): ReactElement => {
  const [currentTab, setCurrentTab] = useState<TNames>(active ?? tabsArr[0])

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    if (onClick) {
      onClick(e.currentTarget.innerText as TNames)
      setCurrentTab(e.currentTarget.innerText as TNames)
    } else {
      setCurrentTab(e.currentTarget.innerText as TNames)
    }
  }

  const isTabActive = (tabName: TNames): boolean => {
    return active ? active === tabName : currentTab === tabName
  }

  return (
    <Box
      sx={{
        ...defaultContainerStyles,
        ...styles,
      }}
    >
      {tabsArr.map((tabName, idx) => (
        <Button
          key={`${tabName}${idx}`}
          onClick={handleClick}
          sx={{
            ...defaultButtonStyles,
            ...tabsStyles,
            color: isTabActive(tabName) ? '#FFF' : 'inherit',
            backgroundColor: isTabActive(tabName) ? '#1068EB' : 'inherit',
            '&:hover': isTabActive(tabName)
              ? {
                  backgroundColor: '#1068EB',
                  opacity: 1,
                }
              : {},
          }}
        >
          {tabName}
        </Button>
      ))}
    </Box>
  )
}
