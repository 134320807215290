import { createTheme } from '@mui/material'

import { theme } from 'src/themes/base'

const customTheme = {
  ...theme,
  typography: {
    ...theme.typography,
    allVariants: {
      ...theme.typography.allVariants,
      color: '#495056',
    },
  },
  components: {
    ...theme.components,

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:webkitAutofill': {
            webkitBoxShadow: '0 0 0 100px #fff inset',
            webkitTextFillColor: '#495056',
          },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        input: {
          '&:webkitAutofill': {
            webkitBoxShadow: '0 0 0 100px #fff inset',
            webkitTextFillColor: '#495056',
          },
        },
      },
    },
  },
  palette: {
    ...theme.palette,
  },
}

export default customTheme
