import { FC, useState } from 'react'

import { Box } from '@mui/system'

import { IconShield } from 'src/assets/icons'
import { IconWithText } from 'src/components/IconWithText'
import { CustomButton, appearanceStyles } from 'src/ui/Button'
import { Typography } from '@mui/material'
import axios from 'axios'
import { toast } from 'react-toastify'
import ConfirmationModal from 'src/ui/Modals/ConfirmationModal'

interface IPanel {
  id: number
  title: string
  description: string
  button: {
    name: string
    appearance: keyof typeof appearanceStyles
    onClick?: () => void
  }
}

export const Privacy: FC = () => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}')
  const role = user.role
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const panelsData: IPanel[] = [
    {
      id: 1,
      title: 'Request all stored account data',
      description: `You can request a file containing your stored data. 
      A link will be emailed to you when the file is ready to be downloaded.`,
      button: {
        name: 'Download Data',
        appearance: 'primary-soft-default',
        onClick: async () => {
          setLoading(true)
          try {
            // sends user data to email as csv
            const response = await axios.get(`/user/download-data`)

            if (response.status === 200) {
              toast.success('File has been sent to your email')
              setLoading(false)
            }
          } catch (error: any) {
            setLoading(false)
            toast.error(error.message)
          }
        },
      },
    },
    {
      id: 2,
      title: 'Review important documents',
      description: `Know your rights, review your privacy policy, 
      terms, and conditions.`,
      button: {
        name: 'Review Terms & Conditions',
        appearance: 'primary-soft-default',
        onClick: () => {
          window.open('/privacy-policy', '_blank')
        },
      },
    },
    {
      id: 3,
      title: 'Close your account and delete account data',
      description: `All stored data connected to your account will be deleted, 
      all records of your actions will be anonymized. This is not undoable.`,
      button: {
        name: 'Delete Account',
        appearance: 'error-soft-default',
        onClick: async () => {
          setShowConfirmationModal(true)
        },
      },
    },
  ]

  const handleDelete = async () => {
    try {
      // deletes the user
      setLoading(true)
      const response = await axios.delete(`/user/delete`)

      if (response.status === 200) {
        setLoading(false)

        localStorage.removeItem('user')
        window.location.href = '/login'
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  return (
    <Box sx={{ mt: '64px' }}>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          message="Are you sure you want to delete your account? This action is not reversable."
          onConfirm={handleDelete}
          show={showConfirmationModal}
          type="delete"
        />
      )}
      <IconWithText
        src={IconShield}
        text="Privacy"
        styles={{
          fontSize: '22px',
          lineHeight: '27px',
          color: '#101213',
        }}
      />
      {panelsData.map((panel) => {
        if (panel.id === 3 && role !== 'globalAdmin') {
          return (
            <Box
              key={panel.title}
              sx={{
                p: '16px 24px 30px',
                mt: '24px',
                bgcolor: '#F1F3F5',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  lineHeight: '22px',
                  color: '#101213',
                }}
              >
                {panel.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 300,
                  mt: '8px',
                }}
              >
                {panel.description}
              </Typography>
              <CustomButton
                disabled={loading}
                appearance={panel.button.appearance}
                onClick={panel.button.onClick}
                styles={{ display: 'block', mt: '24px' }}
              >
                {panel.button.name}
              </CustomButton>
            </Box>
          )
        } else if (panel.id !== 3) {
          return (
            <Box
              key={panel.title}
              sx={{
                p: '16px 24px 30px',
                mt: '24px',
                bgcolor: '#F1F3F5',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  lineHeight: '22px',
                  color: '#101213',
                }}
              >
                {panel.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 300,
                  mt: '8px',
                }}
              >
                {panel.description}
              </Typography>
              <CustomButton
                disabled={loading}
                appearance={panel.button.appearance}
                onClick={panel.button.onClick}
                styles={{ display: 'block', mt: '24px' }}
              >
                {panel.button.name}
              </CustomButton>
            </Box>
          )
        } else {
          return null
        }
      })}
    </Box>
  )
}
