import React, { FC, useEffect } from 'react'

import * as d3 from 'd3'
const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
const PieChart: FC<{ props: any }> = (props: any) => {
  const { data, thresholds, outerRadius, innerRadius } = props.props
  const id = `pie-chart-${uuidv4()}`

  const margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  const width = 2 * outerRadius + margin.left + margin.right
  const height = 2 * outerRadius + margin.top + margin.bottom

  // const colorScale = d3
  //   .scaleLinear<string>()
  //   .domain([0, 1])
  //   .range(['red', 'orange', 'green'])

  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(['normal', 'warning', 'fault', 'failure', 'unknown'])
    .range(['green', 'orange', 'brown', 'red', 'grey'])

  useEffect(() => {
    drawChart()
  }, [data, thresholds])
  const totalValue = data.reduce(
    (total: number, d: any) => total + (d.value as number),
    0,
  )

  function drawChart(): void {
    // Remove the old svg
    d3.select(`#${id}`).select('svg').remove()

    // Create new svg
    const svg = d3
      .select(`#${id}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const arcGenerator: any = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .padAngle(0.2)
      .padRadius(8)
      .cornerRadius(1)

    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d: any) => d.value)

    const arc = svg.selectAll().data(pieGenerator(data)).enter()

    // Append arcs
    arc
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (d: any) => colorScale(d.data.label))
      .style('stroke', '#ffffff')
      .style('stroke-width', 0)

    arc
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      // .text((d: any) => d.data.label)
      .style('fill', (d: any) => {
        console.log(d.data, thresholds)

        if (d.data.value === thresholds.failure) {
          return '#C92A2A'
        } else if (d.data.value === thresholds.warning) {
          return '#E9A100'
        } else if (d.data.value === thresholds.normal) {
          return '#37B24D'
        } else if (d.data.value === thresholds.fault) {
          return '#b95000'
        } else {
          return '#6A7178'
        }
      })

      .attr('transform', (d) => {
        const [x, y] = arcGenerator.centroid(d)
        return `translate(${x}, ${y})`
      })
  }

  return <div id={id}></div>
}

export default PieChart
