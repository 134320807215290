import React, { FC } from 'react'

import { Box, Typography } from '@mui/material'
import { generateUUID } from 'src/helpers/generateUUID'
import SVG from 'react-inlinesvg'
import { Icon3DCube, IconSitemap } from 'src/assets/icons'

const SignalPreview: FC<{ selectedSignals: any }> = ({ selectedSignals }) => {

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '4px',
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: '306px',
        '&::-webkit-scrollbar': {
          width: '9px',
          //   height: '9px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#1068EB',
          borderRadius: '9999px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '0 9999px 9999px 0',
          background: '#CED4DA',
        },
      }}
    >
      <Box
        sx={{
          background: '#F8F9FA',
          padding: '16px 24px',
        }}
      >
        <Typography fontWeight={700} fontSize="18px" color="#101213">
          Selected Signals
        </Typography>
      </Box>
      <Box
        sx={{
          background: '#E9ECEF',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          padding: '24px',
          rowGap: '24px',
        }}
      >
        {selectedSignals.map((signal: any) =>
          signal.bodymakers.map((bodymaker: any) => (
            <Box
              key={generateUUID()}
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <Box component={SVG} src={Icon3DCube} />
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize="14px"
                  color="#101213"
                  mb="5px"
                >
                  {bodymaker.name}
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize="12px"
                  color="#101213"
                  display="flex"
                  alignItems="center"
                  gap="4px"
                >
                  <Box
                    component={SVG}
                    src={IconSitemap}
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                  {signal.name}
                </Typography>
              </Box>
            </Box>
          )),
        )}
      </Box>
    </Box>
  )
}

export default SignalPreview
