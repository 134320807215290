import { FC, ReactNode, useState } from 'react'

import { Stack, Box, SxProps, Theme } from '@mui/material'
import Switch from 'react-switch'

import { IconWithText } from 'src/components/IconWithText'

interface CollapsibleContainerProps {
  icon: string
  title: string
  styles?: SxProps<Theme> | undefined
  children?: ReactNode
  onToggle?: (isExpanded: boolean) => void
  state?: boolean
}

export const CollapsibleContainer: FC<CollapsibleContainerProps> = ({
  children,
  icon,
  title,
  styles,
  onToggle,
  state,
}) => {
  const [isExpanded, setIsExpanded] = useState(!!state)

  return (
    <Box
      sx={{
        transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
        overflow: 'hidden',
        maxHeight: isExpanded ? '1000px' : '40px',
        mb: isExpanded ? '100px' : '48px',
        '&:last-child': {
          mb: isExpanded ? '8px' : '48px',
        },
        ...styles,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <IconWithText
          src={icon}
          text={title}
          styles={{
            fontSize: '22px',
            lineHeight: '27px',
            color: '#101213',
          }}
        />
        <Switch
          checked={isExpanded}
          onChange={() => {
            onToggle && onToggle(!isExpanded)
            setIsExpanded(!isExpanded)
          }}
          offColor="#CED4DA"
          onColor="#1068EB"
          handleDiameter={24}
          uncheckedIcon={false}
          checkedIcon={false}
          borderRadius={45}
          height={32}
          width={52}
        />
      </Stack>
      {children}
    </Box>
  )
}
